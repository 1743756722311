import { useUserSessionStore } from '@velis/django_project_base';
import { ComputedRef } from 'vue';
import { RouteRecordRaw, useRoute, useRouter } from 'vue-router';

import useProjectModulesStore from '../settings/project-modules';

import { Navigation, SortedNavigations } from './namespace';

export const routeToNav = (route: RouteRecordRaw): Navigation => {
  const navName = route.name?.toString();
  return {
    name: navName ?? '',
    title: route.meta?.title ?? '',
    icon: route.meta?.icon,
    action: () => {},
  };
};

const isProjectRoute = (route: RouteRecordRaw): boolean => (!!route.meta?.projectRequired);
const isAppendNavigation = (route: RouteRecordRaw): boolean => (!!route.meta?.navAppend);
const isDefaultNavigation = (route: RouteRecordRaw): boolean => (!route.meta?.navAppend);
const isEmptyRoute = (route: RouteRecordRaw): boolean => (!route.path);

function resolveEmptyChildrenReroute(): undefined;
function resolveEmptyChildrenReroute(route: RouteRecordRaw): RouteRecordRaw;
function resolveEmptyChildrenReroute(route?: RouteRecordRaw): RouteRecordRaw | undefined {
  if (route === undefined) return route;
  const emptyChild = route?.children?.find((childRoute) => isEmptyRoute(childRoute));
  if (emptyChild === undefined) return route;
  route.name = emptyChild.name;
  return route;
}

const showProjectRoute = (route: RouteRecordRaw, projectId: ComputedRef<any>): boolean => (
  !isProjectRoute(route) || !!projectId.value
);

function matchesPermissions(route: RouteRecordRaw): boolean {
  try {
    const userSession = useUserSessionStore();
    return (
      !route.meta?.requiredPermissions ||
      (route.meta.requiredPermissions as string[]).every((perm: string) => userSession.userHasPermission(perm))
    );
  } catch {
    return false;
  }
}

function matchesModules(route: RouteRecordRaw): boolean {
  try {
    const projectModules = useProjectModulesStore();
    const rm: string[] = route.meta?.requiredModules as string[];
    return !rm || rm.length === 0 || rm.every((module: string) => projectModules.moduleActive(module));
  } catch (err: any) {
    // catch because Pinia might not be initialised yet
    return false;
  }
}

export const filterVisibleRoutes = (
  rts: RouteRecordRaw[],
  projectId: ComputedRef<any>,
): RouteRecordRaw[] => rts.map(resolveEmptyChildrenReroute).filter((route) => (
  matchesModules(route) && showProjectRoute(route, projectId) && matchesPermissions(route)
));

export const sortedNavigations = (rts: RouteRecordRaw[], projectId: ComputedRef<any>): SortedNavigations => {
  const filtered = filterVisibleRoutes(rts, projectId);
  const top = filtered.filter(isDefaultNavigation).map(routeToNav);
  const bot = filtered.filter(isAppendNavigation).map(routeToNav);
  return {
    top,
    bot,
  };
};

export const useNavigationUtils = () => {
  const currentRouterPath = useRoute();
  const router = useRouter();

  async function routeNavigation(nav: Navigation): Promise<void> {
    const params = currentRouterPath.params;
    await router.push({ name: nav.name, params });
  }

  function augmentNavAction(navs: SortedNavigations): SortedNavigations {
    for (const nav of navs.bot) {
      nav.action = () => (() => routeNavigation(nav))();
    }
    for (const nav of navs.top) {
      nav.action = () => (() => routeNavigation(nav))();
    }
    return navs;
  }

  return { augmentNavAction };
};
