<template>
  <v-tooltip location="bottom" :text="tooltipStr">
    <template #activator="args">
      <v-chip v-bind="args.props" @click.stop="openDialog">
        <IonIcon class="action-icon" :name="weather?.icon ?? 'add-circle'"/>
      </v-chip>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { apiClient, dfModal, FormConsumerOneShotApi, gettext } from '@velis/dynamicforms';
import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import IonIcon from 'vue-ionicon';

interface Props {
  date: Date;
  canEdit: boolean;
}

interface DailyWeatherJSON {
  id: number;
  icon: string;
  date: string;
  start: string;
  end: string;
  tooltip: string;
  weather: number;
  is_bad_weather: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits(['change']);
const weather = ref<DailyWeatherJSON | undefined>(undefined);

const dateStr = computed<string>(() => (DateTime.fromISO(props.date.toISOString()).toFormat('yyyy-MM-dd')));
const tooltipStr = computed<string>(() => (weather.value?.tooltip ?? gettext('Add daily weather')));

const updateWeather = async (new_weather?: DailyWeatherJSON) => {
  weather.value = new_weather ??
    (await apiClient.get(`/rest-api/v1/daily-weather?date=${dateStr.value}`)).data.shift() ?? undefined;
};

onMounted(async () => {
  await updateWeather();
});

const openDialog = async () => {
  if (!props.canEdit) return;
  let res = await FormConsumerOneShotApi({
    url: '/rest-api/v1/daily-weather',
    trailingSlash: true,
    pk: weather.value?.id ?? 'new',
    query: weather.value?.id ? undefined : { date: dateStr.value },
  });
  if (res === undefined) return;

  await updateWeather(res.data);

  if ([200, 201].includes(res.status) && weather.value?.is_bad_weather) {
    res = await dfModal.yesNo('', 'Would you like to clear reservations on that day?');
    if (res.action.label.toUpperCase() === 'YES') {
      await apiClient.post('/rest-api/v1/daily-weather/bad-weather/', { weather: weather.value });
      emits('change');
    }
  }
};
</script>

<style scoped>

</style>
