import { useUserSessionStore } from '@velis/django_project_base';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, watch } from 'vue';

import routes from '../router/routes';

import { setRootNavigations } from './navigations';
import { sortedNavigations, useNavigationUtils } from './utils';

const useRootNavigations = () => {
  const userSessionStore = useUserSessionStore();
  const { selectedProjectId } = storeToRefs(userSessionStore);

  const { augmentNavAction } = useNavigationUtils();

  const rootRoutes = computed(() => {
    if (!selectedProjectId.value) return { top: [], bot: [] };
    return augmentNavAction(sortedNavigations(routes, selectedProjectId));
  });

  watch(rootRoutes, () => {
    setRootNavigations(rootRoutes.value);
  });

  onBeforeMount(() => {
    if (selectedProjectId.value) setRootNavigations(augmentNavAction(sortedNavigations(routes, selectedProjectId)));
  });
};

export default useRootNavigations;
