<script setup lang="ts">
import {
  CalendarOptions,
  DateSelectArg,
  DateSpanApi,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/vue3';
import { useUserSessionStore } from '@velis/django_project_base';
import { apiClient, FormConsumerOneShotApi, gettext } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

import LocationFilter, { type Location } from '../location-filter.vue';
import UserFilter, { User } from '../user-filter.vue';

const router = useRouter();

const locations = ref<Location[]>();
const description = ref<string>();
const users = ref<User[]>();

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);

const filter = computed(() => {
  let str = '';

  if (locations.value) {
    for (const location of locations.value) {
      str += `${str.length > 0 ? '&' : ''}location[]=${location.id}`;
    }
  }

  if (description.value) {
    str += `${str.length > 0 ? '&' : ''}description=${description.value}`;
  }

  if (users.value) {
    for (const user of users.value) {
      str += `${str.length > 0 ? '&' : ''}workers[]=${user.id}`;
    }
  }

  if (str.length > 0) {
    return `?${str}`;
  }

  return str;
});

const baseUrl: string = '/rest-api/v1/activity';
const url = computed<string>(() => (
  `${baseUrl}.json${filter.value}`
));
const detailUrl = (record_id: string): string => (`${baseUrl}/${record_id}.json`);

const calendarRef = ref<any>();

const getCalendarAPI = () => calendarRef.value?.getApi();

function reloadActivities() {
  // make check login request in order to set current project on server
  userSession.checkLogin(false).then(() => {
    getCalendarAPI()?.refetchEvents();
  });
}

watch(selectedProjectId, reloadActivities);

const eventDataTransform = (input: EventInput): EventInput => (
  { id: input.id, start: input.start, end: input.end, title: input.title }
);

const breakpoints = useDisplay();
const leftToolbar = computed(() => (breakpoints.mdAndUp.value ? 'prev,next today' : <string> <unknown> false));
const rightToolbar = computed(() => (
  breakpoints.mdAndUp.value ? 'listWeek,timeGridDay,timeGridWeek,dayGridMonth' : <string> <unknown> false
));

watch(breakpoints.mdAndUp, (value) => {
  if (calendarRef.value && !value) { getCalendarAPI()?.changeView('timeGridDay'); }
});

const resizeActivity = async (resizeInfo: EventResizeDoneArg | EventDropArg) => {
  const url1 = detailUrl(resizeInfo.event.id);
  try {
    await apiClient.patch(
      url1,
      { id: resizeInfo.event.id, start: resizeInfo.event.startStr, end: resizeInfo.event.endStr },
    );
  } catch (exc) {
    resizeInfo.revert();
  } finally {
    resizeInfo.view.calendar.refetchEvents();
  }
};
const editActivity = async (clickInfo: EventClickArg) => {
  const params = { ...router.currentRoute.value.params, activity: clickInfo.event.id };
  await router.push({ name: 'activity-dashboard', params });
};
const addActivity = async (selectionInfo: DateSelectArg) => {
  const start = new Date(selectionInfo.startStr);
  const end = new Date(selectionInfo.endStr);

  const params = new URLSearchParams({
    start_at: start.toISOString(),
    end_at: end.toISOString(),
  });

  await FormConsumerOneShotApi({
    url: baseUrl,
    trailingSlash: true,
    pk: 'new',
    query: params,
  });
  selectionInfo.view.calendar.refetchEvents();
};

const calendarOptions = computed<CalendarOptions>(() => ({
  plugins: [dayGridPlugin, listPlugin, momentPlugin, momentTimezonePlugin, timeGridPlugin, interactionPlugin],
  headerToolbar: {
    left: leftToolbar.value,
    center: 'title',
    right: rightToolbar.value,
  },
  dayHeaderFormat: 'ddd DD.MMM',
  views: {
    dayGridMonth: { dayHeaderFormat: 'ddd' },
    listWeek: { listDaySideFormat: 'DD.MMM yyyy' },
  },
  titleFormat: 'ddd DD.MMM yyyy',
  height: 'auto',
  slotLabelFormat: 'HH:mm',
  eventStartEditable: true,
  eventDurationEditable: true,
  selectable: true,
  selectMirror: true,
  selectOverlap: true,
  selectAllow(selectInfo: DateSpanApi) {
    return selectInfo.start.getDate() === selectInfo.end.getDate();
  },

  allDaySlot: false,
  eventResize: resizeActivity,
  eventDrop: resizeActivity,

  initialView: breakpoints.mdAndUp.value ? 'timeGridWeek' : 'timeGridDay',
  editable: true,
  dayMaxEvents: true, // allow "more" link when too many events
  eventOverlap: false,

  eventClick: editActivity,
  select: addActivity,

  events: url.value,
  eventDataTransform,
  eventTimeFormat: 'HH:mm',
  timeZone: 'Europe/Ljubljana',
  slotMinTime: '00:00',
  slotMaxTime: '24:00',
  firstDay: 1,
}));

</script>

<template>
  <v-container style="user-select: none" fluid>
    <v-row class="bg-grey-lighten-2 elevation-5" justify="start" align="center" dense>
      <v-col cols="12" sm="4" md="2">
        {{ gettext('Location:') }}
      </v-col>
      <v-col class="justify-center" cols="12" sm="8" md="8">
        <location-filter v-model="locations"/>
      </v-col>
    </v-row>
    <v-row class="bg-grey-lighten-2 elevation-5" justify="start" align="center" dense>
      <v-col cols="12" sm="4" md="2">
        {{ gettext('Description:') }}
      </v-col>
      <v-col class="justify-center" cols="12" sm="8" md="8">
        <v-text-field v-model="description" density="compact" hide-details class="pa-0"/>
      </v-col>
    </v-row>
    <v-row class="bg-grey-lighten-2 elevation-5" justify="start" align="center" dense>
      <v-col cols="12" sm="4" md="2">
        {{ gettext('Users:') }}
      </v-col>
      <v-col class="justify-center" cols="12" sm="8" md="8">
        <user-filter v-model="users"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!breakpoints.mdAndUp.value" cols="12" class="justify-content-center">
        <v-btn class="v-col-3" color="secondary" @click="getCalendarAPI()?.prev()">&lt;</v-btn>
        <v-btn class="v-col-3" color="secondary" @click="getCalendarAPI()?.next()">&gt;</v-btn>
        <v-btn class="v-col-6" color="secondary" @click="getCalendarAPI()?.today()">{{ gettext('today') }}</v-btn>
      </v-col>
    </v-row>
    <v-row class="bg-grey-lighten-5 elevation-5" justify="start" dense>
      <v-col>
        <full-calendar ref="calendarRef" :options="calendarOptions"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
