<template>
  <iframe ref="iframe" title="legacy-page" :src="route.path" @load="onIframeLoad">&nbsp;</iframe>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const iframe = ref<HTMLIFrameElement>();

const onIframeLoad = () => {
  if (iframe.value === undefined || iframe.value.contentWindow == null) return;
  // Store the initial URL of the iframe in the state object
  window.history.replaceState({ iframeUrl: iframe.value.contentWindow.location.href }, '', null);

  // Listen for changes to the iframe location
  iframe.value.contentWindow.addEventListener('popstate', () => {
    if (iframe.value === undefined || iframe.value.contentWindow == null) return;
    // Update the iframe source to the new location
    iframe.value.src = iframe.value.contentWindow.location.href;

    // Add a new entry to the browser history with the new location
    window.history.pushState({ iframeUrl: iframe.value.contentWindow.location.href }, '', null);
  });
};

onMounted(() => {
  window.addEventListener('popstate', (event) => {
    // Check if the state object contains an iframe URL
    if (event.state && event.state.iframeUrl && iframe.value) {
      // Update the iframe source to the stored URL
      iframe.value.src = event.state.iframeUrl;
    }
  });
});

onUnmounted(() => {
  window.removeEventListener('popstate', (event) => {
    // Check if the state object contains an iframe URL
    if (event.state && event.state.iframeUrl && iframe.value) {
      // Update the iframe source to the stored URL
      iframe.value.src = event.state.iframeUrl;
    }
  });
});
</script>

<style scoped>
iframe {
  border: none;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
