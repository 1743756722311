<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, ConsumerLogicApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);
const tagLogic = ref(new ConsumerLogicApi('/rest-api/v1/tag', true));

async function loadTags() {
  await tagLogic.value.getFullDefinition();
}

if (selectedProjectId.value) loadTags();
watch(selectedProjectId, loadTags);
</script>

<template>
  <div class="overflow-y-auto">
    <APIConsumer
      :consumer="tagLogic"
      :display-component="ComponentDisplay.TABLE"
    />
  </div>
</template>
