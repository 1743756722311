import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import { gettext } from '@velis/dynamicforms';
import { createRouter, createWebHashHistory, RouteLocationNormalized } from 'vue-router';

import routes from './routes';

declare module 'vue-router' {
  interface RouteMeta {
    active?: boolean,
    title: string,
    icon?: string,
    projectRoute?: boolean,
    navAppend?: boolean,
    alcRoute?: boolean,
  }
}

function getUserSession() {
  try {
    return useUserSessionStore();
  } catch (err: any) {
    // This happens when this file is imported to add router to the vue instance we're creating.
    // Naturally, pinia is not installed if there isn't the Vue instance to install it in at that time
    // we fake pinia being installed
    return { selectedProjectId: '', selectedProject: { name: '' }, userIsSuperUser: false, loggedIn: false };
  }
}

/*
export function requiresAdmin(route: RouteRecordRaw) {
  const userSession = getUserSession();
  return route.name !== 'users' || userSession.userIsSuperUser;
}
*/

export function doPlaceholderReplacements(str: string) {
  const userSession = getUserSession();
  return str
    .replace(':project_name', userSession.selectedProject?.name || gettext('{put application name here}'))
    .replace(':project', userSession.selectedProjectId);
}

// TODO: this does not cater for permissions. Either we need to get permissions over from backend or
//  the menu needs to be an API point as well
const router = createRouter({
  history: createWebHashHistory(), // import.meta.env.BASE_URL
  routes,
});

router.beforeEach(async (to: RouteLocationNormalized) => {
  const userSession = useUserSessionStore();
  const routeProject = to.params.project;
  if (!routeProject) {
    to.params.project = userSession.selectedProjectId;
  } else if (to.params.project !== userSession.selectedProjectId) {
    // router should have priority to route project value
    try {
      const data = (await apiClient.get(`project/${routeProject}`)).data;
      userSession.setSelectedProject(data);
    } catch (e) {
      console.error('Project with slug does not exist', routeProject);
    }
  }
});

export default router;
