<script setup lang="ts">

import { apiClient } from '@velis/dynamicforms';
import { computed, ref } from 'vue';
import Multiselect from 'vue-multiselect';

export interface User {
  id: number | 'new'
}

const props = withDefaults(defineProps<{
  modelValue: User,
  limit?: number
}>(), { limit: 10 });

const emits = defineEmits<{ (e: 'update:modelValue', value: User): any }>();

const baseUrl = 'rest-api/v1/profile.json';
const loading = ref<boolean>(false);
const users = ref<any[]>([]);

const onSearch = async (searchQuery: string): Promise<void> => {
  const headers = { 'x-viewmode': 'TABLE_ROW', 'x-pagination': 1 };
  let req = baseUrl;
  if (searchQuery) {
    const conditions = [];
    if (searchQuery) conditions.push(`full_name=${searchQuery}`);
    req += `?${conditions.join('&')}`;
  }
  loading.value = true;
  try {
    let loadedData = (await apiClient.get(req, { headers })).data;
    if (Array.isArray(loadedData)) {
      // Pagination was not delivered. We got a plain array
      loadedData = { results: loadedData, next: null };
    }
    users.value = loadedData.results;
  } finally {
    loading.value = false;
  }
};

const selected = computed({
  get(): any {
    return props.modelValue;
  },
  set(newValue: User) {
    emits('update:modelValue', newValue);
  },
});
</script>

<template>
  <Multiselect
    v-model="selected"
    :options="users"
    label="full_name"
    track-by="id"

    :clear-on-select="false"
    :allow-empty="true"
    :multiple="true"
    :taggable="false"
    density="compact"

    :limit="props.limit"
    :loading="loading"
    @search-change="onSearch"
    @open="onSearch"
  />
</template>

<style scoped>

</style>
