<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, FormConsumerApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const userSession = useUserSessionStore();
const editDataLoaded = ref(false);

const { selectedProjectId } = storeToRefs(userSession);
const projectLogic = ref(new FormConsumerApi({ url: '/project/current', trailingSlash: false }));

async function loadProject() {
  editDataLoaded.value = false;
  await projectLogic.value.getUXDefinition();
  editDataLoaded.value = true;
}

if (selectedProjectId.value) loadProject();
watch(selectedProjectId, loadProject);
</script>

<template>
  <v-container class="pa-0" fluid>
    <v-row v-if="editDataLoaded">
      <v-col>
        <APIConsumer :consumer="projectLogic" :display-component="ComponentDisplay.FORM"/>
      </v-col>
    </v-row>
  </v-container>
</template>
