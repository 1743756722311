import _ from 'lodash';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Navigation, NavigationCollection, SortedNavigations } from './namespace';

const navigations = ref<NavigationCollection>({ 0: { top: [], bot: [] } });

const rootNavs = computed<NavigationCollection>(() => {
  if (!navigations.value) return {};
  return { 0: navigations.value[0] } as NavigationCollection;
});

const actionNavs = computed<NavigationCollection>(() => Object.fromEntries(
  Object.entries(navigations.value).filter(
    (item) => item[0] !== '0',
  ).map(
    (item) => ([Number.parseInt(item[0], 10), item[1]]),
  ),
));

/*
const actionNavsMin = computed<number>(() => (
  _.min(Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10))) ?? 0
));
*/
const actionNavsMax = computed<number>(() => (
  _.max(Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10))) ?? 0
));

export const reserveNavigation = (): number => {
  const res = actionNavsMax.value + 1;
  navigations.value[res] = { top: [], bot: [] };
  return res;
};
export const replaceNavigationLevel = (depth: number, subNavigations: SortedNavigations): void => {
  navigations.value[depth] = subNavigations;
};
export const removeNavigationLevel = (depth: number) => { delete navigations.value[depth]; };
export const setRootNavigations = (rts: SortedNavigations) => { navigations.value[0] = rts; };
const useNavigations = () => {
  const currentRouterPath = useRoute();
  const router = useRouter();

  function routeNavigation(nav: Navigation) {
    const params = currentRouterPath.params;
    return router.push({ name: nav.name, params });
  }

  return { navs: navigations, rootNavs, actionNavs, routeNavigation };
};

const showNavigation = ref<boolean>(true);
const useNavigationHandler = () => ({ showNavigation });

export {
  useNavigations,
  useNavigationHandler,
};
