<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import { gettext } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, ref, watch } from 'vue';

import RecommendedActivities from './recommended-activities.vue';

const webrtcUrl = 'https://alc.velis.si/webrtc';
const showVideo = ref(true);

let videoStarted: boolean = false;

async function startVideo() {
  if (videoStarted) return;
  videoStarted = true;
  function startPlay(videoEl: HTMLVideoElement, url: string) {
    const webrtc = new RTCPeerConnection({
      iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
      sdpSemantics: 'unified-plan',
    });
    webrtc.ontrack = async function rtrcTrack(event) {
      console.log(`${event.streams.length} track is delivered`);
      videoEl.srcObject = event.streams[0];
      try {
        await videoEl.play();
      } catch (err) {
        console.warn('Error playing video, hiding container', err);
        showVideo.value = false;
      }
    };
    webrtc.addTransceiver('video', { direction: 'sendrecv' });
    webrtc.onnegotiationneeded = async function handleNegotiationNeeded() {
      const offer = await webrtc.createOffer();

      await webrtc.setLocalDescription(offer);

      fetch(url, {
        method: 'POST',
        body: new URLSearchParams({ data: btoa(webrtc?.localDescription?.sdp || '') }),
      })
        .then((response) => response.text())
        .then((data) => {
          try {
            webrtc.setRemoteDescription(
              new RTCSessionDescription({ type: 'answer', sdp: atob(data) }),
            );
          } catch (e) {
            console.warn(e);
          }
        });
    };

    const webrtcSendChannel = webrtc.createDataChannel('rtsptowebSendChannel');
    webrtcSendChannel.onopen = () => {
      console.log(`${webrtcSendChannel.label} has opened`);
      webrtcSendChannel.send('ping');
    };
    webrtcSendChannel.onclose = () => {
      console.log(`${webrtcSendChannel.label} has closed`);
      startPlay(videoEl, url);
    };
    webrtcSendChannel.onmessage = (event) => console.log(event.data);
  }

  let i = 0;
  while (i++ < 5) {
    const videoEl = document.querySelector('#webrtc-video') as HTMLVideoElement;
    if (videoEl) {
      startPlay(videoEl, webrtcUrl);
      break;
    }
    console.log('video not ready yet');
    // eslint-disable-next-line no-await-in-loop,no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 250));
  }
}

onMounted(() => {
  startVideo();
});

interface BlogEntry {
  title: string;
  body: string;
  author: string;
  date: string;
}
const blogEntries = ref([] as Array<BlogEntry>);
(async () => { blogEntries.value = (await apiClient.get('/blog/latest')).data; })();

const userSession = useUserSessionStore();
const { selectedProjectId } = storeToRefs(userSession);

watch(selectedProjectId, (newValue) => { if (newValue === 'alc-lesce') nextTick(startVideo); });
</script>

<template>
  <v-container class="mx-md-16 my-md-8">
    <v-row v-if="selectedProjectId === 'alc-lesce'">
      <v-col>
        <video v-if="showVideo" id="webrtc-video" autoplay muted playsinline controls>&nbsp;</video>
        <v-alert v-else type="warning">{{ gettext('Error playing camera feed') }}</v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedProjectId">
      <v-col>
        <h1>{{ gettext('Recommended Activities') }}</h1>
        <RecommendedActivities/>
      </v-col>
    </v-row>
    <v-row v-for="blog in blogEntries" :key="blog.title">
      <v-col>
        <h1>{{ blog.title }}</h1>
        <div v-html="blog.body"/>
        <div style="font-style: italic; font-size: smaller; margin-top: .5em">
          {{ blog.author }}, {{ new Date(blog.date).toLocaleString('sl-SI') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
video {
  max-width:  100%;
  max-height: 15em;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
video:hover {
  max-height: 100%;
}
</style>
