import { useUserSessionStore } from '@velis/django_project_base';
import { apiClient } from '@velis/dynamicforms';
import { AxiosRequestConfig } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { watch, WatchStopHandle } from 'vue';

export enum Plans {
  // mirrors Plans from plan.py
  FREE = 0,
  BASIC = 1,
  PRO = 2,
  ULTIMATE = 3,
}

export enum Modules {
  PLAN = 'plan',
  ACTIVITIES = 'activities',
  NOTIFICATIONS = 'notifications',
  RESERVATIONS = 'reservations',
  INVOICING = 'invoicing',
  PAYMENT_PROCESSOR = 'payment-processor',
  AVIATION = 'aviation',
  CAMERA = 'camera',
}

export interface Module {
  code: string;
  description: string;
  icon: string;
  enabled: boolean;
  params: object;
  stopWatcher: WatchStopHandle,
}

export interface ProjectModulesData {
  plan: Plans;
  modules: { [key: string]: Module };
  dataLoaded: boolean; // has the data been loaded already
}

const useProjectModulesStore = defineStore('project-modules', {
  state: (): ProjectModulesData => ({
    plan: Plans.FREE,
    modules: {},
    dataLoaded: false,
  }),
  getters: {
    apiEndpointList() { return '/rest-api/v1/active-modules/'; },
    apiEndpointUpdate() { return (module: string) => `/rest-api/v1/active-modules/${module}/`; },

    /**
     * indicates whether the given module is enabled or not
     * @return: true when module is active, false otherwise
     */
    moduleActive: (state) => (moduleName: string): boolean => (!!(state.modules[moduleName]?.enabled)),
  },
  actions: {
    async ensureData() {
      if (this.dataLoaded) return true;
      const userSession = useUserSessionStore();
      const { selectedProjectId } = storeToRefs(userSession);
      watch(selectedProjectId, async () => { await this.loadData(); }, { immediate: true });

      return true;
    },

    async loadData() {
      Object.values(this.modules).forEach((module: Module) => module.stopWatcher());
      // this.$reset();
      try {
        const result = await apiClient.get(
          this.apiEndpointList,
          { hideErrorNotice: true } as AxiosRequestConfig,
        );
        result.data.forEach((module: Module & { plan: number }) => {
          if (module.code === 'plan') {
            this.plan = module.plan;
          } else {
            // console.log(module);
            this.modules[module.code] = module;
            if (this.modules[module.code].stopWatcher == null) {
              const sw = watch(() => this.modules[module.code].enabled, async (newValue: boolean) => {
                await apiClient.patch(
                  this.apiEndpointUpdate(module.code),
                  { enabled: newValue },
                  { hideErrorNotice: true } as AxiosRequestConfig,
                );
              });
              module.stopWatcher = sw;
            }
          }
        });
        this.dataLoaded = true;
        return result;
      } catch (err: any) {
        console.error(err);
        return err;
      }
    },
  },
});

export default () => {
  const store = useProjectModulesStore();
  store.ensureData();
  return store;
};
