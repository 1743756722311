<template>
  <div v-if="errorText">{{ errorText }}</div>
  <APIConsumer v-else-if="consumer" :key="selectedProjectId" :consumer="consumer" :display-component="displayMode"/>
  <div v-else/>
</template>

<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, ConsumerLogicApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { onMounted, ref, Ref, shallowRef, watch } from 'vue';
import { RouteLocationNormalized, useRoute } from 'vue-router';

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);
const consumer = shallowRef<APIConsumer.ConsumerLogicBaseInterface | undefined>();
// test: undefined as FormLayout | undefined,
const errorText: Ref<string | undefined> = ref();
const displayMode = ref(ComponentDisplay.TABLE);

const route = useRoute();
const emit = defineEmits(['title-change', 'load-route']);

async function goToRoute(to: RouteLocationNormalized) {
  const consumerTemp = new ConsumerLogicApi(to.meta.path);
  try {
    await consumerTemp.getFullDefinition();
    errorText.value = undefined;
    consumer.value = consumerTemp;
    emit('title-change', consumer.value.title(displayMode.value === ComponentDisplay.TABLE ? 'table' : 'new'));
  } catch (err: any) {
    console.error(err);
    errorText.value = err.toString();
  }
}

onMounted(() => { goToRoute(route); });
watch(() => route.path, () => goToRoute(route));
</script>
