<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, ConsumerLogicApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const rolesLoaded = ref(false);
const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);
const roleLogic = ref(new ConsumerLogicApi('/rest-api/v1/role', true));

async function loadRoles() {
  await roleLogic.value.getFullDefinition();
  rolesLoaded.value = true;
}

if (selectedProjectId.value) loadRoles();
watch(selectedProjectId, loadRoles);
</script>

<template>
  <div class="overflow-y-auto" style="width: 25em">
    <APIConsumer
      v-if="selectedProjectId && rolesLoaded"
      :consumer="roleLogic"
      :display-component="ComponentDisplay.TABLE"
    />
  </div>
</template>
