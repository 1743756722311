<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import {
  Action,
  APIConsumer,
  ComponentDisplay,
  FormConsumerApi,
  FormPayload,
  useActionHandler,
} from '@velis/dynamicforms';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
// @ts-ignore
import SmsCounter from 'sms-counter';
import { stripHtml } from 'string-strip-html';
import { Ref, ref, watch } from 'vue';

import { Navigations } from '../navigation/namespace';
import { useSubNavigation } from '../navigation/sub-navigations';

interface Setting {
  title: Ref<string>;
  key: string;
  value: string;
}

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);

const smsLinkTextSettingName = 'notification-sms-link-text';

const settingLoaded = ref(false);
const selectedSettingKey = ref('');

const loadedSettingKey = ref('');

const settings = ref<Navigations>([]);
const settingUrl = '/rest-api/v1/notification-settings';
const settingLogic = ref(new FormConsumerApi(
  {
    url: settingUrl,
    trailingSlash: true,
    pk: undefined,
  },
));
useSubNavigation(settings);

async function selectSetting(setting: Setting) {
  selectedSettingKey.value = setting.key;
  // @ts-ignore
  settingLogic.value = new FormConsumerApi(
    {
      url: settingUrl,
      trailingSlash: true,
      pk: selectedSettingKey,
    },
  );
  await settingLogic.value.getUXDefinition();
  settingLoaded.value = true;
  loadedSettingKey.value = setting.key;
}

const singleSettingHandlers = {
  value_changed: (action: Action, payload: FormPayload) => {
    if (payload.name === smsLinkTextSettingName) {
      let textVal = stripHtml(payload.value).result;
      if (_.includes(textVal, '__LINK__')) {
        textVal = _.replace(
          textVal,
          '__LINK__',
          `${window.location.origin}/notification/b68bb635-d832-4c6e-bc92-57cbd6d471c0/info/`,
        );
      }
      const smsCounter = SmsCounter.count(textVal);
      payload.number_sms_consumed = smsCounter.messages;
      payload.number_char_remaining = smsCounter.remaining;
    }
    return false;
  },
  submit: () => {
    setTimeout(() => {
      const stgg = _.cloneDeep(selectedSettingKey.value);
      settingLoaded.value = false;
      selectSetting({ title: ref(''), key: stgg, value: '' });
      // TODO: FORM CANNOT BE UPDATED IN THIS CASE
      // https://taiga.velis.si/project/velis74-alc/issue/848
    }, 4000);
    return false;
  },
};

function load() {
  settings.value = [];
  apiClient.get(`${settingUrl}/`).then((response) => {
    _.each(response.data, (item) => {
      settings.value.push({
        title: item.description,
        name: item.name,
        action: () => selectSetting({ title: item.description, key: item.name, value: item.value }),
      });
    });
    if (_.size(settings.value)) {
      settings.value[0].action();
    }
  });
}

if (selectedProjectId.value) load();
watch(selectedProjectId, load);

const actionConfirmActiveSettings = async (action: Action, payload: { [x: string]: any; }): Promise<boolean> => {
  apiClient.post('/project-settings/confirm-setting-active', { id: payload.id }).then(() => {
    settingLoaded.value = false;
    selectSetting({ title: payload.description, key: payload.name, value: payload.value });
  });
  return true;
};

const actionResetPendingSetting = async (action: Action, payload: { [x: string]: any; }): Promise<boolean> => {
  apiClient.post('/project-settings/reset-pending', { id: payload.id }).then(() => {
    settingLoaded.value = false;
    selectSetting({ title: payload.description, key: payload.name, value: payload.value });
  });
  return true;
};

const { handler } = useActionHandler();

handler.register(
  'confirm-active-setting',
  actionConfirmActiveSettings,
).register(
  'reset-pending-setting',
  actionResetPendingSetting,
);

</script>

<template>
  <APIConsumer
    v-if="selectedSettingKey && settingLoaded"
    :key="loadedSettingKey"
    :consumer="settingLogic"
    :handlers="singleSettingHandlers"
    :display-component="ComponentDisplay.FORM"
  />
</template>
