<template>
  <v-container style="user-select: none" fluid>
    <v-row class="bg-grey-lighten-2 elevation-5" justify="start" align="center" dense>
      <v-col cols="12" sm="4" md="2">
        {{ gettext('Reservations for plane:') }}
      </v-col>
      <v-col class="justify-center" cols="12" sm="8" md="8">
        <Multiselect
          v-model="selected"
          :options="frequentPlanes"
          label="name_with_type"
          track-by="id"

          group-values="data"
          group-label="label"

          :allow-empty="false"
          :multiple="false"
          :taggable="false"
          density="compact"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-btn v-if="hasMaintenanceRights" block color="primary" @click="addMaintenance">
          {{ gettext('Maintenance') }}
        </v-btn>
      </v-col>
      <v-col v-if="!breakpoints.mdAndUp.value" cols="12" class="justify-content-center">
        <v-btn class="v-col-3" color="secondary" @click="getCalendarAPI()?.prev()">&lt;</v-btn>
        <v-btn class="v-col-3" color="secondary" @click="getCalendarAPI()?.next()">&gt;</v-btn>
        <v-btn class="v-col-6" color="secondary" @click="getCalendarAPI()?.today()">{{ gettext('today') }}</v-btn>
      </v-col>
    </v-row>
    <v-row class="bg-grey-lighten-5 elevation-5" justify="start" dense>
      <v-col>
        <full-calendar ref="calendarRef" :options="calendarOptions">
          <template #dayHeaderContent="arg">
            <template v-if="arg.view.type !== 'dayGridMonth'">
              <DailyWeather
                :date="arg.date"
                :can-edit="hasDailyWeatherRights"
                @change="getCalendarAPI()?.refetchEvents()"
              />
            </template>
            {{ arg.text }}
          </template>
        </full-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import type {
  CalendarOptions,
  DateSelectArg,
  DateSpanApi,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/vue3';
import { useUserSessionStore } from '@velis/django_project_base';
import { apiClient, dfModal, FormConsumerOneShotApi, gettext } from '@velis/dynamicforms';
import SunCalc from 'suncalc';
import { computed, ref, watch } from 'vue';
import Multiselect from 'vue-multiselect';
import { useDisplay } from 'vuetify';

import DailyWeather from './daily-weather.vue';
import { FrequentPlane } from './namespace';

const userSession = useUserSessionStore();

const hasMaintenanceRights = computed<boolean>(() => userSession.userHasPermission('plane_maintenance'));
const hasDailyWeatherRights = computed<boolean>(() => userSession.userHasPermission('daily_weather'));

const selected = ref<FrequentPlane | undefined>(undefined);
const frequentPlanes = ref<{ label: string, data: Array<FrequentPlane> }[]>([]);
apiClient.get('rest-api/v1/frequent-planes.json').then((response: any) => {
  frequentPlanes.value = [
    { label: 'Frequent', data: [] },
    { label: 'Planes', data: [] },
  ];

  response.data.map((plane: FrequentPlane) => {
    frequentPlanes.value[plane.frequent ? 0 : 1].data.push(plane);
    return plane;
  });

  if (frequentPlanes.value.length) {
    selected.value = response.data[0];
  }
});

watch(selected, (value: any) => {
  if (value.safety_notice) {
    dfModal.message('', value.safety_notice);
  }
});

const planeId = computed<number | undefined>(() => (selected.value?.id));

const baseUrl: string = '/rest-api/v1/plane-reservation';
const url = computed<string>(() => (
  (planeId.value == null) ? '' : `${baseUrl}.json?plane=${planeId.value}`
));

const detailUrl = (record_id: string): string => (`${baseUrl}/${record_id}.json`);
const calendarRef = ref<any>(null);

const maintenanceUrl = '/rest-api/v1/plane-maintenance';

function getCalendarAPI() {
  return calendarRef.value?.getApi();
}

// popravit backend, da bo skonfiguriral UserRelatedField tako, da bo kot query parameter padel še trenutno izbran avion
// popravit potem Viewset, ki userje zagotavlja, da bo vrnil ustrezno sfiltriran nabor userjev...

const addMaintenance = async () => {
  await FormConsumerOneShotApi({
    url: maintenanceUrl,
    trailingSlash: true,
    pk: 'new',
    query: { plane: planeId.value },
  });
  getCalendarAPI()?.refetchEvents();
};

const resizeReservation = async (resizeInfo: EventResizeDoneArg | EventDropArg) => {
  const url1 = detailUrl(resizeInfo.event.id);
  try {
    await apiClient.patch(
      url1,
      { id: resizeInfo.event.id, start: resizeInfo.event.startStr, end: resizeInfo.event.endStr },
    );
  } catch (exc) {
    resizeInfo.revert();
  } finally {
    resizeInfo.view.calendar.refetchEvents();
  }
};
const editReservation = async (clickInfo: EventClickArg) => {
  const eventId = clickInfo.event.id;
  await FormConsumerOneShotApi({
    url: baseUrl,
    trailingSlash: true,
    pk: eventId,
  });
  clickInfo.view.calendar.refetchEvents();
};
const addReservation = async (selectionInfo: DateSelectArg) => {
  const start = new Date(selectionInfo.startStr);
  const end = new Date(selectionInfo.endStr);

  if (selected.value?.reg.startsWith('#REZ-')) {
    start.setHours(10, 0, 0, 0);
    end.setHours(20, 0, 0, 0);
  }
  const params = new URLSearchParams({
    plane: planeId.value!.toString(),
    reserved_by: userSession.userId.toString(),
    start: start.toISOString(),
    end: end.toISOString(),
  });

  await FormConsumerOneShotApi({
    url: baseUrl,
    trailingSlash: true,
    pk: 'new',
    query: params,
  });
  selectionInfo.view.calendar.refetchEvents();
};
const eventDataTransform = (input: EventInput): EventInput => (
  { id: input.id, start: input.start, end: input.end, title: input.title }
);

function formatTimeAsString(date: Date) {
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}

const sunriseTime = ref('8:00');
const sunsetTime = ref('16:00');
const recalculateSun = (dateInfo: DatesSetArg) => {
  const suncalc = SunCalc.getTimes(dateInfo.start, 46.05, -14.50);
  const sunrise = new Date(suncalc.sunrise.valueOf() - (-suncalc.sunrise.getTimezoneOffset() + 30) * 60 * 1000);
  const sunset = new Date(suncalc.sunset.valueOf() + (suncalc.sunrise.getTimezoneOffset() + 30) * 60 * 1000);
  sunriseTime.value = formatTimeAsString(sunrise);
  sunsetTime.value = formatTimeAsString(sunset);
};

const breakpoints = useDisplay();
const leftToolbar = computed(() => (breakpoints.mdAndUp.value ? 'prev,next today' : <string> <unknown> false));
const rightToolbar = computed(() => (
  breakpoints.mdAndUp.value ? 'listWeek,timeGridDay,timeGridWeek,dayGridMonth' : <string> <unknown> false
));

watch(breakpoints.mdAndUp, (value) => {
  if (calendarRef.value && !value) { getCalendarAPI()?.changeView('timeGridDay'); }
});

const calendarOptions = computed<CalendarOptions>(() => ({
  plugins: [dayGridPlugin, listPlugin, momentPlugin, momentTimezonePlugin, timeGridPlugin, interactionPlugin],
  headerToolbar: {
    left: leftToolbar.value,
    center: 'title',
    right: rightToolbar.value,
  },
  dayHeaderFormat: 'ddd DD.MMM',
  views: {
    dayGridMonth: { dayHeaderFormat: 'ddd' },
    listWeek: { listDaySideFormat: 'DD.MMM yyyy' },
  },
  titleFormat: 'ddd DD.MMM yyyy',
  height: 'auto',
  slotLabelFormat: 'HH:mm',
  eventStartEditable: true,
  eventDurationEditable: true,
  selectable: true,
  selectMirror: true,
  selectOverlap: false,
  selectAllow(selectInfo: DateSpanApi) {
    return selectInfo.start.getDate() === selectInfo.end.getDate() &&
      formatTimeAsString(selectInfo.start) >= sunriseTime.value &&
      formatTimeAsString(selectInfo.end) <= sunsetTime.value;
  },

  allDaySlot: false,
  eventResize: resizeReservation,
  eventDrop: resizeReservation,

  initialView: breakpoints.mdAndUp.value ? 'timeGridWeek' : 'timeGridDay',
  editable: true,
  dayMaxEvents: true, // allow "more" link when too many events
  eventOverlap: false,

  eventClick: editReservation,
  select: addReservation,

  events: url.value,
  eventDataTransform,
  eventTimeFormat: 'HH:mm',
  timeZone: 'Europe/Ljubljana',
  slotMinTime: '04:00',
  slotMaxTime: '22:00',
  businessHours: {
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    startTime: sunriseTime.value,
    endTime: sunsetTime.value,
  },
  firstDay: 1,
  eventConstraint: 'businessHours',

  datesSet: recalculateSun,
}));
</script>
