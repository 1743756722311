<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import {
  APIConsumer,
  ConsumerLogicApi,
  ComponentDisplay,
  dfModal,
  gettext,
  FilteredActions,
  Action,
  DialogSize,
  useActionHandler,
  FormConsumerOneShotApi,
} from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { h, ref, watch } from 'vue';

import usePhoneCall from './phone-call';

// import showAddProfileModal from './profile-search-add-user'; // TODO: refactor after DPB is published

const userSession = useUserSessionStore();
const { selectedProjectId } = storeToRefs(userSession);
const usersLoaded = ref(false);

const consumerLogic = ref<ConsumerLogicApi>(new ConsumerLogicApi('/rest-api/v1/profile', true));
const consumerLogicMerge = ref<ConsumerLogicApi>(new ConsumerLogicApi('/account/profile-merge', false));

const consumerLogicNotify = ref<ConsumerLogicApi>(new ConsumerLogicApi('/rest-api/v1/profile-notify-selected', false));

consumerLogic.value.filterData = { 'remove-merge-users': true, slug: userSession.selectedProject?.slug };
consumerLogicMerge.value.filterData = { slug: userSession.selectedProject?.slug };

const mergeViewVisible = ref(false);
const notifyViewVisible = ref(false);

const updateMergeView = () => {
  consumerLogicMerge.value.reload().then(() => {
    // @ts-ignore
    mergeViewVisible.value = consumerLogicMerge.value.rows.data.length > 0;
  });
};

(async () => {
  await consumerLogicMerge.value.getFullDefinition();
  updateMergeView();
})();

async function loadUsers() {
  await consumerLogic.value.getFullDefinition();
  usersLoaded.value = true;
  updateMergeView();
  await (async () => {
    await consumerLogicNotify.value.getFullDefinition();
    consumerLogicNotify.value.reload().then(() => {
      if (consumerLogicNotify.value.rows.data.length > 0 && !mergeViewVisible.value) {
        notifyViewVisible.value = true;
      }
    });
  })();
}

if (selectedProjectId.value) loadUsers();
watch(selectedProjectId, loadUsers);

const actionMergeUsers = async () => {
  await apiClient.post('account/profile-merge').then(updateMergeView);
  return true;
};

const actionClearMergeUsers = async () => {
  await apiClient.delete('account/profile-merge/clear').then(() => {
    consumerLogic.value.reload();
    updateMergeView();
  });
  return true;
};

const actionAddToMerge = async (action: any, payload: any) => {
  await apiClient.post('account/profile/merge', { user: payload.id }).then(() => {
    updateMergeView();
    consumerLogic.value.reload();
  });
  return true;
};

const actionDeleteUser = async (action: any, payload: any) => {
  if (action.icon === 'person-remove-outline') {
    await apiClient.delete(`account/profile-merge/${payload.id}`).then(() => {
      updateMergeView();
      consumerLogic.value.reload();
    });
  }
  return true;
};

const actionResetPassword = async (action: any, payload: any) => {
  const userConfirmation = await dfModal.yesNo(
    gettext('Reset password'),
    gettext('Do you really want to reset user password?'),
  );
  if (userConfirmation.action.name === 'yes') {
    await apiClient.post('/account/admin-invalidate-password/', { user_id: payload.id });
    const modalMessage = await dfModal.message(
      gettext('Reset password'),
      () => [
        h(
          'h5',
          {},
          gettext('User password was reset. You can impersonate user and change their password.'),
        ),
      ],
      new FilteredActions({
        confirm: new Action({
          name: 'impersonate',
          label: gettext('impersonate'),
          icon: 'thumbs-up-outline',
          displayStyle: { asButton: true, showLabel: true, showIcon: true },
          position: 'FORM_FOOTER',
        }),
        cancel: new Action({
          name: 'cancel',
          label: gettext('Cancel'),
          icon: 'thumbs-down-outline',
          displayStyle: { asButton: true, showLabel: true, showIcon: true },
          position: 'FORM_FOOTER',
        }),
      }),
      { size: DialogSize.SMALL },
    );
    if (modalMessage.action.name === 'impersonate') {
      apiClient.post('/account/impersonate', { id: payload.id }).then(() => {
        window.location.reload();
      });
    }
  }
  return true;
};

const actionExport = async () => {
  const result = await FormConsumerOneShotApi({
    url: '/rest-api/v1/profile/export/',
    trailingSlash: true,
    pk: 'new',
    query: { filter_data: consumerLogic.value.filterData },
  });
  if (result) {
    const response = await apiClient.post(
      '/rest-api/v1/profile/export/download/',
      result,
      { responseType: 'blob', hideErrorNotice: false },
    );

    if (response.status === 200 && response.data) {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', result.file_name);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  }
  return true;
};

const actionNotifySelectedUser = async (action: any, payload: any) => {
  await apiClient.post('/rest-api/v1/profile-notify-selected/', { id: payload.id }).then(() => {
    consumerLogicNotify.value.reload().then(() => {
      notifyViewVisible.value = true;
    });
  });
  return true;
};

const actionRemoveNotifySelectedUser = async (action: any, payload: any) => {
  await apiClient.delete(`/rest-api/v1/profile-notify-selected/${payload.id}`).then(() => {
    consumerLogicNotify.value.reload().then(() => {
      if (!consumerLogicNotify.value.rows.data.length) {
        notifyViewVisible.value = false;
      }
    });
  });
  return true;
};

const actionNotifySelectedUsersMake = async () => {
  await FormConsumerOneShotApi({ url: '/rest-api/v1/profile-notify-selected', trailingSlash: true, pk: 'new' });
  consumerLogicNotify.value.reload().then(() => {
    if (!consumerLogicNotify.value.rows.data.length) {
      notifyViewVisible.value = false;
    }
  });
  return true;
};

/*
const handleSelectedProfile = async (profile: any | undefined) => {
  if (profile && profile.id) {
    apiClient.post('/rest-api/v1/profile/assign-member/', { member: profile.id }).catch((err) => {
      dfModal.message(
        gettext('Error'),
        err.response.status === 400 ? gettext('Invalid member') : gettext('Unknown error occurred'),
      ).then(() => {
        // showAddProfileModal(handleSelectedProfile, '/account/projects-profile-search');
      });
    });
    return;
  }
  await FormConsumerOneShotApi({
    url: '/rest-api/v1/profile',
    trailingSlash: true,
    pk: 'new',
    query: { email: profile.email },
  });
};
*/

const { actionCallUser } = usePhoneCall();

const actionInviteUser = async () => {
  await FormConsumerOneShotApi({
    url: '/account/project-user-invite',
    trailingSlash: false,
    pk: 'new',
  });
  return true;
};

const { handler } = useActionHandler();

handler
  .register('merge-users', actionMergeUsers)
  .register('clear-merge-users', actionClearMergeUsers)
  .register('add-to-merge', actionAddToMerge)
  .register('delete-user', actionDeleteUser)
  .register('export', actionExport)
  .register('reset-password', actionResetPassword)
  .register('notify-selected-user', actionNotifySelectedUser)
  .register('remove-notify-selected-user', actionRemoveNotifySelectedUser)
  .register('notify-selected-users-make', actionNotifySelectedUsersMake)
  .register('invite-user', actionInviteUser)
  .register('call-user', actionCallUser);
</script>
<template>
  <div class="full-width fill-height">
    <div class="fill-height" style="overflow-y: auto;">
      <APIConsumer
        :consumer="consumerLogic"
        :display-component="ComponentDisplay.TABLE"
        class="fill-height"
      />
    </div>
    <div v-if="mergeViewVisible" style="max-width: 25%; overflow-y: auto;" class="fill-height">
      <APIConsumer :consumer="consumerLogicMerge" :display-component="ComponentDisplay.TABLE" class="fill-height"/>
    </div>
    <div v-else-if="notifyViewVisible" style="max-width: 25%; overflow-y: auto;">
      <APIConsumer :consumer="consumerLogicNotify" :display-component="ComponentDisplay.TABLE" style="height: 100vh;"/>
    </div>
  </div>
</template>

<style scoped>
.full-width {
  display: flex;
}

.full-width > * {
  flex: 1;
}
</style>
