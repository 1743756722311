<script setup lang="ts">
import { apiClient } from '@velis/django_project_base';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useRouteSubNavigation } from '../navigation/sub-navigations';

const route = useRoute();
useRouteSubNavigation(route.matched[1]);

const props = defineProps<{ activity:string }>();

type Activity = {
  title: string
  date: string,
};

const activityData = ref<Activity>();
const dateString = computed<string>(() => {
  if (activityData.value?.date) {
    const date = new Date(activityData.value?.date);
    return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
  }
  return '';
});

onMounted(async () => {
  activityData.value = (await apiClient.get(`/rest-api/v1/activity/${props.activity}/`)).data;
});
</script>

<template>
  <div>
    <v-toolbar class="gradient-background">
      <v-toolbar-title>
        <span>{{ (activityData?.title ?? '').toUpperCase() }}</span>
        <span class="mx-1">-</span>
        <span>{{ dateString }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <router-view/>
  </div>
</template>

<style>
.gradient-background {
  /* Set gradient background using linear-gradient */
  background: linear-gradient(to right,
                              #3f51b5, /* Your primary color */
                              black /* Your primary accent color */
                            );
  /* Ensure text contrast */
  color: white; /* Adjust text color as needed */
}
</style>
