<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { gettext } from '@velis/dynamicforms';
import { computed, ref } from 'vue';
import IonIcon from 'vue-ionicon';
import { useDisplay } from 'vuetify';

import useProjectModulesStore, { Plans } from './project-modules';

const userSession = useUserSessionStore();
const projectModules = useProjectModulesStore();

const plans = [
  { plan: Plans.FREE, title: gettext('Free'), icon: 'beer-outline' },
  { plan: Plans.BASIC, title: gettext('Basic'), icon: 'bicycle-outline' },
  { plan: Plans.PRO, title: gettext('Pro'), icon: 'car-sport-outline' },
  { plan: Plans.ULTIMATE, title: gettext('Ultimate'), icon: 'boat-outline' },
];

const display = ref(useDisplay());
const isClubOwner = computed(() => userSession.userHasPermission('is_club_owner'));
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row><v-col class="text-center mt-5"><h2>Plans</h2></v-col></v-row>
        <v-row>
          <v-col v-for="plan in plans" :key="plan.plan" class="text-center pb-6">
            <div :class="{ 'plan-button': true, selected: projectModules.plan === plan.plan }">
              <ion-icon :name="plan.icon"/>
              <h3>{{ plan.title }}</h3>
              <div v-if="plan.plan !== Plans.FREE" class="hover-text">{{ gettext('Coming soon') }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p class="text-h6 mb-6">
              Choosing a plan affects all of your clubs while modifying the modules applies to current club only
            </p>
            <hr/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-for="module in projectModules.modules"
      :key="module.code"
      class="align-center"
      :title="!isClubOwner ? gettext('You need to be club owner to modify state of the modules') : null"
    >
      <v-col cols="2" sm="1" md="1"><ion-icon :name="module.icon" style="width: 3em"/></v-col>
      <v-col cols="8" sm="10" md="9" xl="10">{{ module.description }}</v-col>
      <v-col cols="2" sm="1" md="2" xl="1">
        <v-switch
          v-model="module.enabled"
          :disabled="!isClubOwner"
          :label="display.smAndDown ? null : (module.enabled ? gettext('enabled') : gettext('disabled'))"
          color="primary"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.plan-button {
  display: inline-block;
  width: 10em;
  text-align: center;
  border: .25em solid gray;
  border-radius: 1em;
  padding: 1em;
  transition: transform 0.2s ease;
}
.plan-button.selected {
  background-color: lightgray;
  transform: scale(1.25);
}
.plan-button:hover {
  transform: scale(1.25);
}
.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  display: none;
  white-space: nowrap;
  font-size: 2em;
  color: orange;
}

.plan-button:hover .hover-text {
  display: block;
}
</style>
