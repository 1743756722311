<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import {
  APIConsumer,
  ComponentDisplay,
  ConsumerLogicApi,
  FormConsumerApi,
  FormConsumerOneShotApi,
} from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import saveMode from './activity-save-mode';

interface Props {
  activity: string
}

const props = defineProps<Props>();
const selectedActivity = computed(() => props.activity);

const allowedMembersUrl = computed(() => `/rest-api/v1/activity/${props.activity}/allowed-members`);
const membersSettingsUrl = computed(() => '/rest-api/v1/activity/members-settings');

const sessionStore = useUserSessionStore();
const { selectedProjectId } = storeToRefs(sessionStore);

class CustomConsumerLogic extends ConsumerLogicApi {
  async dialogForm(
    pk: number,
  ) {
    const result = await FormConsumerOneShotApi(
      {
        url: this.baseUrl,
        trailingSlash: this.trailingSlash,
        pk,
      },
      this.dialogHandlers,
    );
    await this.reload();

    return result;
  }
}

const allowMembersConsumer = ref(new CustomConsumerLogic(allowedMembersUrl, true));
const membersSettingsConsumer = ref(new FormConsumerApi(
  {
    url: membersSettingsUrl,
    trailingSlash: true,
    pk: selectedActivity,
  },
));

const showConsumers = ref<boolean>(false);

const refresh = async () => {
  showConsumers.value = false;
  await allowMembersConsumer.value.getFullDefinition();
  await membersSettingsConsumer.value.getUXDefinition();
  showConsumers.value = true;
};

refresh();
watch(selectedProjectId, refresh);

const settingsHandlers = {
  submit: async (_: any, payload: any) => {
    await saveMode(payload);
    return false;
  },
};

const membersHandlers = {
  recurrence: async () => {
    const pL = {} as any;
    await saveMode(pL);
    if (pL.mode) {
      await apiClient.post(`${allowedMembersUrl.value}/recurrence/`, pL);
    }
    return true;
  },
};
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <APIConsumer
          v-if="showConsumers"
          :handlers="settingsHandlers"
          :consumer="membersSettingsConsumer"
          :display-component="ComponentDisplay.FORM"
        />
      </v-col>
    </v-row>
    <v-row v-if="showConsumers" dense>
      <v-col cols="12">
        <APIConsumer
          :handlers="membersHandlers"
          :consumer="allowMembersConsumer"
          :display-component="ComponentDisplay.TABLE"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
