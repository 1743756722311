<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import {
  Action,
  APIConsumer,
  ComponentDisplay,
  ConsumerLogicApi, dfModal,
  FormConsumerApi,
  FormConsumerOneShotApi,
  FormPayload, gettext,
  useActionHandler,
} from '@velis/dynamicforms';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';

const userGroupsLoaded = ref(false);
const editPk = ref<number | string>();
const editDataLoaded = ref(false);
const display = useDisplay();

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);
const userGroupsLogic = ref(new ConsumerLogicApi('/rest-api/v1/user-groups', true));
let singleGroupLogic = ref(new FormConsumerApi({ url: '/rest-api/v1/user-groups', trailingSlash: true }));

const canFitBothConsumers = computed(() => display.width.value >= 1417);

async function loadUserGroups() {
  editPk.value = undefined;
  editDataLoaded.value = false;
  await userGroupsLogic.value.getFullDefinition();
  userGroupsLoaded.value = true;
}

if (selectedProjectId.value) loadUserGroups();
watch(selectedProjectId, loadUserGroups);

async function actionEdit(
  actionData: Action,
  payload: FormPayload | undefined | null,
) {
  singleGroupLogic.value.errors = {};
  if (!canFitBothConsumers.value || !payload?.id) return false; // default dialog editing
  editDataLoaded.value = false;
  editPk.value = payload?.id;
  singleGroupLogic = ref(
    new FormConsumerApi({ url: '/rest-api/v1/user-groups', trailingSlash: true, pk: editPk.value }),
  );
  await singleGroupLogic.value.getUXDefinition();
  editDataLoaded.value = true;
  return true;
}

const actionNotifyGroup = async (action: Action, payload: { [x: string]: any; }): Promise<boolean> => {
  await FormConsumerOneShotApi({
    url: '/rest-api/v1/user-groups/notify/group/',
    trailingSlash: true,
    pk: payload.id,
  });
  return true;
};
const userGroupHandlers = {
  delete: async (unused: Action, payload: FormPayload): Promise<boolean> => {
    const res = await dfModal.yesNo('Delete', gettext('Are you sure you want to delete this record?'));
    if (res.action.name.toUpperCase() === 'YES') {
      await userGroupsLogic.value.deleteRow(payload);
      if (editPk.value === payload.id) {
        editPk.value = undefined;
      }
    }
    return true;
  },
  edit: actionEdit,
};

async function actionCallUser(unused: Action, payload: FormPayload) {
  if (_.isString(payload.phone_number) && (/^([+0])?\d+$/g).test(payload.phone_number)) {
    let number = payload.phone_number;
    if (!(/^[+0]/ig).test(number)) number = `+${number}`;
    if (display.platform.value.touch) {
      // We're assuming that if this is a touch device, it knows how to handle phone calls. Else we show an error
      window.location.href = `tel:${number}`;
    } else {
      await dfModal.message(gettext('Error'), gettext('You browser does not know how to call this phone number'));
    }
  } else {
    await dfModal.message(gettext('Error'), gettext('This member does not have a valid phone number'));
  }
  return true;
}

const singleUserGroupHandlers = {
  cancel: async () => {
    editDataLoaded.value = false;
    return false;
  },
  submit: async (action: Action, payload: FormPayload): Promise<boolean> => {
    singleGroupLogic.value.data = payload;
    singleGroupLogic.value.save().then(() => {
      editDataLoaded.value = false;
      userGroupsLogic.value.reload();
    }).catch((err) => {
      singleGroupLogic.value.errors = err?.response?.data;
    });
    return true;
  },
  'call-user': actionCallUser,
};

const { handler } = useActionHandler();

handler.register('notify-group', actionNotifyGroup);

defineExpose({ breakpoints: display });
</script>

<template>
  <div class="full-width overflow-y-auto">
    <v-container v-if="selectedProjectId && userGroupsLoaded" class="mx-0 pa-1 d-inline-block">
      <APIConsumer
        :consumer="userGroupsLogic"
        :handlers="userGroupHandlers"
        :dialog-handlers="singleUserGroupHandlers"
        :display-component="ComponentDisplay.TABLE"
      />
    </v-container>
    <v-container v-if="editPk && editDataLoaded && canFitBothConsumers" class="mx-0 pa-1 d-inline-block">
      <APIConsumer
        :consumer="singleGroupLogic"
        :handlers="singleUserGroupHandlers"
        :display-component="ComponentDisplay.FORM"
      />
    </v-container>
  </div>
</template>

<style scoped>
.d-inline-block:first-child {
  width: 35em
}

.d-inline-block {
  vertical-align: top;
  max-width: 50em
}
</style>
