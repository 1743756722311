import { useUserSessionStore } from '@velis/django_project_base';
import { storeToRefs } from 'pinia';
import { computed, MaybeRef, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { Navigations, SortedNavigations } from './namespace';
import {
  removeNavigationLevel,
  replaceNavigationLevel,
  reserveNavigation,
  useNavigationHandler,
} from './navigations';
import { sortedNavigations, useNavigationUtils } from './utils';

const useSubNavigation = (subNavigations: MaybeRef<Navigations>): void => {
  const depth = reserveNavigation();
  const navs = ref(subNavigations);
  const sortedNavs = computed<SortedNavigations>(() => ({ top: navs.value, bot: [] }));

  watch(navs, () => {
    if (depth > 0) replaceNavigationLevel(depth, sortedNavs.value);
  });

  onMounted(() => {
    replaceNavigationLevel(depth, sortedNavs.value);
  });

  onBeforeMount(() => {
    const { showNavigation } = useNavigationHandler();
    showNavigation.value = true;
  });

  onUnmounted(() => {
    removeNavigationLevel(depth);
  });
};

const useRouteSubNavigation = (route: RouteRecordRaw) => {
  const depth = reserveNavigation();
  const userSessionStore = useUserSessionStore();
  const { selectedProjectId } = storeToRefs(userSessionStore);

  const { augmentNavAction } = useNavigationUtils();

  onMounted(() => {
    replaceNavigationLevel(
      depth,
      augmentNavAction(sortedNavigations(route.children ?? [], selectedProjectId)),
    );
  });

  onBeforeMount(() => {
    const { showNavigation } = useNavigationHandler();
    showNavigation.value = true;
  });

  onUnmounted(() => {
    removeNavigationLevel(depth);
  });
};

export {
  useSubNavigation,
  useRouteSubNavigation,
};
