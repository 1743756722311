<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);

const notifications = ref(1);

function loadNotifications() {
  notifications.value = Math.round(Math.random() * 100);
}

if (selectedProjectId.value) loadNotifications();
watch(selectedProjectId, loadNotifications);

</script>

<template>
  <notifications-editor
    :key="notifications"
    consumer-url="rest-api/v1/project-notification"
    :consumer-url-trailing-slash="true"
  />
</template>
