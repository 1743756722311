<script setup lang="ts">

import { ComponentDisplay, ConsumerLogicApi, APIConsumer, apiClient, dfModal, gettext } from '@velis/dynamicforms';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const url = ref<string>('/rest-api/v1/activity-recommended');

const consumer = ref(new ConsumerLogicApi(url, true));

const refresh = async () => (consumer.value.getFullDefinition());

const router = useRouter();

onMounted(async () => {
  await refresh();
});

const handlers = {
  enroll: async (_: unknown, payload: any) => {
    if (payload.enrolled) {
      await dfModal.message(gettext('Message'), gettext('Already enrolled'));
      return true;
    }
    const confirm = await dfModal.yesNo(
      gettext(`${payload.title} enroll`),
      gettext('Are you sure you want to enroll?'),
    );
    if (confirm.action.name === 'yes') {
      await apiClient.post(`${url.value}/${payload.id}/`, { enroll: true });
      await refresh();
    }
    return true;
  },
  withdraw: async (_: unknown, payload: any) => {
    if (!payload.enrolled) {
      await dfModal.message(gettext('Message'), gettext('Cannot withdraw if not enrolled'));
      return true;
    }
    const confirm = await dfModal.yesNo(
      gettext(`${payload.title} withdraw`),
      gettext('Are you sure you want to withdraw?'),
    );
    if (confirm.action.name === 'yes') {
      await apiClient.post(`${url.value}/${payload.id}/`, { enroll: false });
      await refresh();
    }
    return true;
  },
  edit: async (_: unknown, payload: any) => {
    const params = { ...router.currentRoute.value.params, activity: payload.id };
    router.push({ name: 'activity-dashboard', params });
    return true;
  },
};
</script>

<template>
  <APIConsumer :consumer="consumer" :display-component="ComponentDisplay.TABLE" :handlers="handlers"/>
</template>

<style scoped>

</style>
