<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import {
  APIConsumer,
  ComponentDisplay,
  dfModal,
  FormConsumerApi,
  gettext,
} from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import saveMode from './activity-save-mode';

const props = defineProps<{
  activity: string,
}>();

const userSession = useUserSessionStore();
const { selectedProjectId } = storeToRefs(userSession);
const settingLoaded = ref(false);

const selectedActivity = computed(() => props.activity);

const router = useRouter();

const activityLogic = ref(new FormConsumerApi(
  {
    url: '/rest-api/v1/activity/dashboard/',
    trailingSlash: true,
    pk: selectedActivity.value,
  },
));

async function load() {
  await activityLogic.value.getUXDefinition();
  settingLoaded.value = true;
}

if (selectedProjectId.value) load();
watch(selectedProjectId, load);

const singleActivityHandlers = {
  enroll: () => {
    apiClient.post(`/rest-api/v1/activity-recommended/${selectedActivity.value}/`, { enroll: true }).then(() => {
      window.location.reload();
    });
    return true;
  },
  withdraw: () => {
    apiClient.post(`/rest-api/v1/activity-recommended/${selectedActivity.value}/`, { enroll: false }).then(() => {
      window.location.reload();
    });
    return true;
  },
  delete: async () => {
    const res = await dfModal.yesNo(gettext('Delete'), gettext('Are you sure you want to delete this record?'));
    if (res.action.name.toUpperCase() === 'YES') {
      try {
        await activityLogic.value.delete();
        await router.replace({ name: 'activity-overview' });
      } catch (err: any) {
        activityLogic.value.withErrors(err.response.data);
      }
    }
    return true;
  },
  submit: async (_: any, payload: any) => {
    await saveMode(payload);
    return false;
  },
};
</script>

<template>
  <div class="fill-height">
    <v-card class="flex-grow-1">
      <v-card-text>
        <APIConsumer
          v-if="settingLoaded"
          :consumer="activityLogic"
          :handlers="singleActivityHandlers"
          :display-component="ComponentDisplay.FORM"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
