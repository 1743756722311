<template>
  <v-app>
    <v-app-bar>
      <title-bar
        :page-title="`&#9776; ${pageTitle}`"
        :breadcrumbs-component="null"
        :adjust-document-title="false"
        @title-click="showNavigation = !showNavigation"
      />
    </v-app-bar>
    <navigation/>
    <v-main>
      <router-view/>
    </v-main>
    <dpb-app/>
    <CookieNotice :options="cookieOptions"/>
  </v-app>
</template>
<script setup lang="ts">
import { apiClient, DpbApp, useUserSessionStore } from '@velis/django_project_base';
import { gettext } from '@velis/dynamicforms';
import _, { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { RouteLocationNormalized, useRouter } from 'vue-router';

import Navigation from './navigation/navigation-component.vue';
import { useNavigationHandler } from './navigation/navigations';
import useRootNavigations from './navigation/root-navigations';
import { doPlaceholderReplacements } from './router';
import useProjectModulesStore from './settings/project-modules';

const userSession = useUserSessionStore();
function superUserCheckStrategy(state: any, permissionName: string) {
  return state.userData.isSuperUser || (permissionName !== 'is_club_owner' && state.userHasPermission('is_club_owner'));
}
userSession.superUserCheckStrategy = superUserCheckStrategy;

const { selectedProjectId, userId, userData } = storeToRefs(userSession);
const permissions = toRef(userData.value, 'permissions');

const projectModules = useProjectModulesStore();

const { showNavigation } = useNavigationHandler();

useRootNavigations();

const cookieOptions = {
  current_lang: 'en',
  autoclear_cookies: true,
  auto_language: true,
  page_scripts: true,
  remove_cookie_tables: true,
  // force_consent: true,
  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description: 'Hi, this website uses cookies to ensure its proper operation. ' +
            'These cookies are essential for the proper functioning of this website.' +
            ' Without these cookies, the website would not work properly.',
        primary_btn: {
          text: 'Accept',
          role: 'accept_all',
        },
      },
      settings_modal: {
        cookie_table_headers: [],
        blocks: [],
      },
    },
  },
};

const router = useRouter();

// We need to debounce the currently selected project and routes, otherwise the menus will be redrawing way too much
const debouncedSelectedProjectId = ref(selectedProjectId.value);
let debouncedActiveModules = Object.fromEntries(
  Object.entries(projectModules.modules).map(([key, value]) => [key, value.enabled]),
);
const debouncedPermissions = ref(permissions.value);

const currentRouterPath = computed<RouteLocationNormalized>(() => router.currentRoute.value);

const pageTitle = computed<string>(() => (
  doPlaceholderReplacements(currentRouterPath.value.meta.title as string ?? gettext('{put application name here}'))
));

async function getALCLesceProject() {
  const res = await apiClient.get('/project?slug=alc-lesce');
  if (res.data.length && !selectedProjectId.value) {
    userSession.setSelectedProject(res.data[0]);
  }
}
watch(userId, getALCLesceProject);

getALCLesceProject();

watch([selectedProjectId, projectModules.$state, permissions], () => {
  const newActiveModules = Object.fromEntries(
    Object.entries(projectModules.modules).map(([key, value]) => [key, value.enabled]),
  );
  debounce(() => {
    if (
      debouncedSelectedProjectId.value !== selectedProjectId.value ||
      Object.keys(debouncedActiveModules).some((key) => newActiveModules[key] !== debouncedActiveModules[key]) ||
      !_.isEqual(debouncedPermissions.value, permissions.value)
    ) {
      debouncedSelectedProjectId.value = selectedProjectId.value;
      debouncedActiveModules = newActiveModules;
      debouncedPermissions.value = _.cloneDeep(permissions.value);

      const name = router.currentRoute.value.name ?? undefined;
      const params = { ...router.currentRoute.value.params, project: debouncedSelectedProjectId.value };
      router.push({ name, params });
    }
  }, 150)();
});
</script>
