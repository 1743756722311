<script setup lang="ts">
import { useRoute } from 'vue-router';

import { useRouteSubNavigation } from '../navigation/sub-navigations';

const route = useRoute();
useRouteSubNavigation(route.matched[0]);
</script>

<template>
  <router-view/>
</template>
