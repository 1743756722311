import { Action, dfModal, FormPayload, gettext } from '@velis/dynamicforms';
import _ from 'lodash';
import { useDisplay } from 'vuetify';

const usePhoneCall = () => {
  const display = useDisplay();

  async function callNumber(phoneNumber?: string) {
    if (_.isString(phoneNumber) && (/^([+0])?\d+$/g).test(phoneNumber)) {
      let number = phoneNumber;
      if (!(/^[+0]/ig).test(number)) number = `+${number}`;
      if (display.platform.value.touch) {
        // We're assuming that if this is a touch device, it knows how to handle phone calls. Else we show an error
        window.location.href = `tel:${number}`;
      } else {
        await dfModal.message(gettext('Error'), gettext('You browser does not know how to call this phone number'));
      }
    } else {
      await dfModal.message(gettext('Error'), gettext('This member does not have a valid phone number'));
    }
    return true;
  }

  async function actionCallUser(action: Action, payload: FormPayload) {
    return callNumber(payload.phone_number);
  }

  return { actionCallUser, callNumber };
};

export default usePhoneCall;
