import { Action, dfModal, FilteredActions, gettext } from '@velis/dynamicforms';

// TODO: cannot type payload... DF types not exported
async function saveMode(payload: any, propertyName: string = 'mode') {
  /// Opens a dialog and set the mode to the payload
  const res = await dfModal.message(
    '',
    gettext('This is one activity in a recurring series.' +
      'Which series items would you like to apply these changes to? '),
    new FilteredActions({
      current: new Action({ name: 'current', label: gettext('Just this instance'), position: 'FORM_FOOTER' }),
      future: new Action(
        { name: 'future', label: gettext('This instance and all after it'), position: 'FORM_FOOTER' },
      ),
      all: new Action(
        { name: 'all', label: gettext('All activities in the series'), position: 'FORM_FOOTER' },
      ),
      cancel: Action.closeAction(),
    }),
  );
  const actionName = res.action.name;
  if (actionName === 'current') {
    payload[propertyName] = 1;
  } else if (actionName === 'future') {
    payload[propertyName] = 2;
  } else if (actionName === 'all') {
    payload[propertyName] = 3;
  }
}

export default saveMode;
