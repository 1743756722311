<script setup lang="ts">
import { gettext } from '@velis/dynamicforms';
import _ from 'lodash';
import { computed, nextTick, ref, watch } from 'vue';
import IonIcon from 'vue-ionicon';
import { useRoute } from 'vue-router';

import { doPlaceholderReplacements } from '../router';
import { getIcon } from '../utils';

import { Navigation, NavigationCollection } from './namespace';
import { useNavigationHandler, useNavigations } from './navigations';

const { showNavigation } = useNavigationHandler();
const { rootNavs, actionNavs } = useNavigations();
const currentRouterPath = useRoute();

const actionNavsExists = computed<boolean>(() => !!Object.keys(actionNavs.value).length);
const actionLevel = ref<number>(0);
watch(actionNavs, () => {
  actionLevel.value = _.max(Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10))) ?? 0;
}, { immediate: true });

const clickHandler = async (nav: Navigation) => {
  const oldMax = _.max(Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10))) ?? 0;
  await nav.action();
  await nextTick();
  const newMax = _.max(Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10))) ?? 0;
  showNavigation.value = newMax > oldMax;
};

const actionLevels = computed<number[]>(() => Object.keys(actionNavs.value).map((key) => Number.parseInt(key, 10)));
const actionsPrevious = computed<number | undefined>(() => {
  const keys = _.cloneDeep(actionLevels.value).sort().reverse();
  for (const key of keys) {
    if (key < actionLevel.value) return key;
  }
  return undefined;
});
const actionsNext = computed<number | undefined>(() => {
  const keys = _.cloneDeep(actionLevels.value).sort();
  for (const key of keys) {
    if (key > actionLevel.value) return key;
  }
  return undefined;
});

const setLevel = (level?: number) => {
  if (level === undefined) return;
  if (!(level in Object.keys(actionNavs).map(Number.parseInt))) return;
  actionLevel.value = level;
};
const setPreviousLevel = () => setLevel(actionsPrevious.value);
const setNextLevel = () => setLevel(actionsNext.value);

const currentActionNavs = computed<NavigationCollection>(() => {
  const res: NavigationCollection = {};
  res[actionLevel.value] = actionNavs.value[actionLevel.value];
  return res;
});
</script>

<template>
  <v-dialog
    v-model="showNavigation"
    :fullscreen="true"
    transition="dialog-bottom-transition"
    :scrim="true"
  >
    <v-card style="user-select: none">
      <v-toolbar color="primary">
        <v-btn icon @click="showNavigation = false">
          <ion-icon name="close" style="width: 1.5em; height: 1.5em; margin: auto"/>
        </v-btn>
        <v-toolbar-title>{{ gettext('Menu') }}</v-toolbar-title>
      </v-toolbar>
      <v-layout class="fill-height">
        <v-navigation-drawer
          v-for="(navigations, key) in rootNavs"
          :key="key"
          :permanent="true"
          :rail="actionNavsExists"
        >
          <v-list density="compact" :nav="true" class="fill-height">
            <v-list-item
              v-for="route in navigations.top"
              :key="route?.title"
              :active="currentRouterPath?.name === route.name"
              @click="clickHandler(route)"
            >
              <template v-if="route.icon" #prepend>
                <ion-icon
                  v-if="route.icon"
                  :name="getIcon(route.icon)"
                  style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
                />
              </template>
              <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <template #append>
            <v-list density="compact" nav class="fill-height">
              <v-list-item
                v-for="route in navigations.bot"
                :key="route?.title"
                :active="currentRouterPath?.name === route.name"
                @click="clickHandler(route)"
              >
                <template v-if="route.icon" #prepend>
                  <ion-icon
                    v-if="route.icon"
                    :name="getIcon(route.icon)"
                    style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
                  />
                </template>
                <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-navigation-drawer>
        <v-main v-if="actionNavsExists">
          <v-toolbar color="secondary">
            <v-btn icon :disabled="!actionsPrevious" @click="setPreviousLevel">
              <ion-icon name="chevron-back" style="width: 1.5em; height: 1.5em; margin: auto"/>
            </v-btn>
            <v-btn icon :disabled="!actionsNext" @click="setNextLevel">
              <ion-icon name="chevron-forward" style="width: 1.5em; height: 1.5em; margin: auto"/>
            </v-btn>
          </v-toolbar>
          <aside v-for="(navigations, key) in currentActionNavs" :key="key">
            <v-list density="compact" nav class="fill-height">
              <v-list-item
                v-for="route in navigations.top"
                :key="route?.title"
                :active="currentRouterPath?.name === route.name"
                @click="clickHandler(route)"
              >
                <template v-if="route.icon" #prepend>
                  <ion-icon
                    v-if="route.icon"
                    :name="getIcon(route.icon)"
                    style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
                  />
                </template>
                <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="route in navigations.bot"
                :key="route?.title"
                :active="currentRouterPath?.name === route.name"
                @click="clickHandler(route)"
              >
                <template v-if="route.icon" #prepend>
                  <ion-icon
                    v-if="route.icon"
                    :name="getIcon(route.icon)"
                    style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
                  />
                </template>
                <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </aside>
        </v-main>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
