const icons: Record<string, any> = {
  // region Icon declarations
  chrono: `
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="marks">
          <path d="M32,26l0,-8M46,40l8,0M32,54l0,8M18,40l-8,0"/>
        </symbol>
        <symbol id="marks-small" stroke-width="2.5">
          <path d="M32,23l0,-5M49,40l5,0M32,57l0,5M15,40l-5,0"/>
        </symbol>
      </defs>
      <g stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="32" cy="40" r="22" fill="none"/>
        <use xlink:href="#marks"/>
        <use xlink:href="#marks-small" transform="rotate(30,32,40)"/>
        <use xlink:href="#marks-small" transform="rotate(60,32,40)"/>
        <path d="M26,18l0,-4 -6,0 0,-5 24,0 0,5 -18,0 12,0 0,4" fill="none" stroke-width="2"/>
        <path d="M23,9l0,5m2.5,-5 0,5m2.5,-5 0,5m2.5,-5 0,5m2.5,-5 0,5m2.5,-5 0,5m2.5,-5 0,5m2.5,-5 0,5"
        stroke-width="1"/>
      </g>
    </svg>
`,
  users: 'person-circle-outline',
  user_groups: 'people-circle-outline',
  tags: 'pricetags-outline',
  invites: 'person-add-outline',
  notifications: 'mail-unread-outline',
  'settings-outline': 'settings-outline',
  role: 'document-lock-outline',
  plane: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612">
<g>
<path d="M612,195.722c0,105.87-510.374,222.727-549.02,222.727c-21.667,0-46.25-4.994-46.25-24.969
c0-6.674,7.703-16.32,21.22-27.949l-0.015,0.008L2.109,301.883c-4.851-8.618-1.005-19.536,8.175-23.212l6.157-2.465
c11.159-4.468,23.718-3.802,34.341,1.821l65.039,34.432c25.323-15.02,54.049-30.746,84.326-46.229L73.402,184.001
c-10.027-6.506-9.92-21.22,0.201-27.578l10.368-6.514c17.256-10.841,38.234-14.043,57.938-8.846l210.85,55.616
c65.742-26.094,125.181-43.904,160.363-43.904c9.396,0,18.806,0.249,27.887,0.898l-63.675,28.745l-1.524,21.418l98.29-45.183
C597.661,164.272,612,174.759,612,195.722z M355.492,378.476l78.648,89.344c4.793,5.445,12.599,7.084,19.176,4.027l10.533
-4.896c19.208-8.928,30.521-29.172,28.061-50.209l-9.846-84.186c-0.61-5.209-5.896-8.506-10.843-6.764l-112.308,39.562
C353.447,367.281,351.661,374.125,355.492,378.476z"/>
</g>
</svg>
`,
  intent: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
  <path d="M160 260h-18.8l-30.6-44.53-.17-.22-.3-.38-.29-.32-.23-.24a8.15 8.15 0 0 0-.36-.34l-.21-.18-.4-.3-.23-.16
  -.38-.24-.3-.16a2.8 2.8 0 0 0-.35-.17l-.37-.17-.32-.12-.42-.14-.31-.08-.44-.1-.37-.06-.4-.05a8.13 8.13 0 0 0-.51
  -.03L104 212H87l-.31.02c-.15 0-.3 0-.44.02-.17.01-.33.04-.5.07l-.26.03-.03.01-.56.14-.23.06-.5.17-.25.1c-.13.05
  -.24.1-.36.17-.12.05-.24.1-.36.17l-.19.11c-.16.1-.33.19-.48.3a7.96 7.96 0 0 0-1.19.98l-.02.01-.49.55c-.1.11-.18.24
  -.27.36l-.18.24c-.13.19-.24.38-.35.58l-.05.08c-.1.18-.19.36-.27.55l-.07.14-.17.45-.1.29c-.04.1-.06.21-.09.32l-.11.45
  -.04.22-.09.57-.01.19c-.02.2-.03.4-.03.62v.27l.03.52.07.47.04.28.02.09v.02L86.51 260h-19.2l-13.65-13.66-.17-.15c-.14
  -.13-.27-.26-.42-.37-.1-.09-.21-.17-.32-.24l-.3-.23-.37-.22-.3-.18-.37-.18-.35-.16-.34-.13-.4-.14-.33-.08a3.34
  3.34 0 0 0-.43-.1c-.12-.03-.24-.04-.37-.06l-.4-.06c-.2-.02-.4-.03-.61-.03L48 244h-.18l-.6.04c-.14.01-.28.04-.41.06
  l-.37.05-.43.11-.33.09-.4.14c-.11.04-.23.07-.34.12l-.35.16-.36.18-.31.18-.36.22-.31.23c-.1.08-.22.15-.32.24l-.42.37
  c-.05.06-.11.1-.17.15l-.15.18-.37.4-.26.35-.2.29c-.1.12-.17.25-.24.38l-.17.29-.19.39-.15.32-.14.38-.12.36-.1.39-.1.37
  -.06.45-.05.32c-.02.26-.04.53-.04.8V284l.03.48a7.98 7.98 0 0 0 13.63 6.18L68.3 276h18.2l-7.35 38.38v.02l-.02.1-.04.27
  c-.02.16-.05.31-.06.47l-.03.52-.01.24v.04l.03.61a7.73 7.73 0 0 0 .1.75l.04.24.11.44.09.33.1.28c.06.15.1.3.17.45l.07.14
  c.08.19.17.37.27.56l.05.08c.1.19.22.38.35.57l.18.24c.1.12.18.25.28.36a8.12 8.12 0 0 0 1.66 1.53c.01 0 .02 0 .03.02
  l.48.29.2.11c.1.07.23.12.35.18l.36.16.26.1.5.18.22.05.56.14h.04l.36.06.36.05c.26.03.53.05.8.05H104l.07-.01c.28 0 .55
  -.02.82-.05l.24-.03.66-.12.25-.07a7.9 7.9 0 0 0 1.46-.54l.23-.11c.26-.14.5-.28.75-.44l.15-.11c.18-.14.37-.28.54-.43
  l.25-.23c.15-.13.28-.26.41-.4l.22-.25a8 8 0 0 0 .47-.59l.07-.1L141.21 276H160a8 8 0 0 0 0-16zm-63.32-32h3.11l22 32
  h-18.98l-6.13-32zm25.11 48-22 32h-3.11l6.12-32h19zM272 300h-64a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16zm0-40h-64
  a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16zm-64-24h64a8 8 0 0 0 0-16h-64a8 8 0 0 0 0 16zm152 16a8 8 0 0 0-8 8v56a8 8 0 0 0 16 0
  v-56a8 8 0 0 0-8-8zm0-40a8 8 0 0 0-8 8v8a8 8 0 0 0 16 0v-8a8 8 0 0 0-8-8z"/>
  <path d="M408 84H72c-22.06 0-40 17.94-40 40v48.8A40.07 40.07 0 0 0 0 212v112c0 22.06 17.94 40 40 40h336
  c22.06 0 40-17.94 40-40v-48.8a40.07 40.07 0 0 0 32-39.2V124c0-22.06-17.94-40-40-40zM16 324V212c0-13.23 10.77-24 24-24
  h272v160H40c-13.23 0-24-10.77-24-24zm112.68-184h3.11l22 32h-18.98l-6.13-32zM400 324c0 13.23-10.77 24-24 24h-48V188
  h48c13.23 0 24 10.77 24 24v112zm32-88a24.04 24.04 0 0 1-16 22.62V212a40 40 0 0 0-16-31.98V172a8 8 0 0 0-16 0v.8
  c-2.59-.52-5.26-.8-8-.8H173.2l-30.6-44.53-.17-.22c-.1-.13-.19-.26-.3-.38l-.28-.32c-.08-.08-.15-.17-.24-.24
  a7.74 7.74 0 0 0-.35-.33l-.22-.2-.38-.28-.25-.17-.37-.23-.31-.18-.34-.16-.38-.17-.3-.11-.44-.15-.3-.08-.45-.1-.36
  -.06-.4-.05-.5-.03-.26-.01h-17l-.31.02c-.15 0-.3 0-.44.02-.17.01-.33.04-.5.07l-.26.03-.03.01-.56.14-.23.06-.5.17
  -.25.1c-.13.05-.24.1-.36.17-.12.05-.24.1-.36.17l-.19.11c-.16.1-.33.19-.48.3a7.96 7.96 0 0 0-1.19.98l-.02.01-.49.55
  c-.1.11-.18.24-.27.36l-.18.24c-.13.19-.24.38-.35.58l-.05.08c-.1.18-.19.36-.27.55l-.07.14-.17.45-.1.29c-.04.1-.06.21
  -.09.32l-.11.45-.04.22-.09.57v.19c-.03.2-.04.4-.04.62v.03l.01.24c0 .18.01.35.03.52.01.16.04.31.06.47l.04.28.02.09
  v.02l7.35 38.38h-19.2l-13.65-13.66-.17-.15a3.7 3.7 0 0 0-.42-.37c-.1-.09-.21-.17-.32-.24l-.3-.23-.37-.22-.3-.18-.37
  -.18-.35-.16-.34-.13-.4-.14-.33-.08a3.34 3.34 0 0 0-.43-.1c-.12-.03-.24-.04-.37-.06l-.4-.06c-.2-.02-.4-.03-.61-.03
  L80 156h-.18l-.6.04c-.14.01-.28.04-.41.06l-.37.05-.43.11-.33.09-.4.14c-.11.04-.23.07-.34.12l-.35.16-.36.18-.31.18
  -.36.22-.31.23c-.1.08-.22.15-.32.24l-.42.37c-.05.06-.11.1-.17.15l-.15.18-.37.4-.26.35-.2.29c-.1.12-.17.25-.24.38
  l-.17.29-.19.39-.15.32-.14.38-.12.36-.1.39-.1.37-.06.45-.05.32c-.02.26-.04.53-.04.8V172H48v-48c0-13.23 10.77-24 24-24
  h272v48a8 8 0 0 0 16 0v-48h48c13.23 0 24 10.77 24 24v112z"/>
  <path d="M304 132h-64a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16zm88-8a8 8 0 0 0-8 8v8a8 8 0 0 0 16 0v-8a8 8 0 0 0-8-8z"/>
</svg>
`,
  calendar: `
<svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
<path d="M192 1664h288v-288h-288v288zm352 0h320v-288h-320v288zm-352-352h288v-320h-288v320zm352 0h320v-320h-320v320zm
-352-384h288v-288h-288v288zm736 736h320v-288h-320v288zm-384-736h320v-288h-320v288zm768 736h288v-288h-288v288zm-384
-352h320v-320h-320v320zm-352-864v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5
9.5h64q13 0 22.5-9.5t9.5-22.5zm736 864h288v-320h-288v320zm-384-384h320v-288h-320v288zm384 0h288v-288h-288v288zm32-480
v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm384-64
v1280q0 52-38 90t-90 38h-1408q-52 0-90-38t-38-90v-1280q0-52 38-90t90-38h128v-96q0-66 47-113t113-47h64q66 0 113 47t47
113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z"/>
</svg>
`,
  forum: `
    <svg viewBox="-1 0 21 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
    <g fill="#000000" transform="translate(-210.000000, -41.000000)">
    <g transform="translate(210.000000, 41.000000)">
    <path d="M19,4 L17,4 L17,13 L4,13 L4,15 C4,15.6 4.4,16 5,16 L16,16 L20,20 L20,5 C20,4.4 19.6,4 19,4 L19,4 Z M15,10
    L15,1 C15,0.4 14.6,0 14,0 L1,0 C0.4,0 0,0.4 0,1 L0,15 L4,11 L14,11 C14.6,11 15,10.6 15,10 L15,10 Z"/>
    </g></g></g>
    </svg>
  `,
  activity: `
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 705 732">
      <path d="M456.8 4.6C442.5 8 427.6 19.9 421.3 33c-10.7 22-6.9 47.6 9.7 65.3 7.3 7.9 18.2 13.8 29.5 16.2 8.8 1.8 
               13.2 1.8 22 0 31.4-6.6 51.3-37.5 44-68.4-4.6-19.4-21.3-36.7-40.1-41.5-7.5-1.9-21.8-2-29.6 0z"/>
      <path d="M612 52.3c-23.1 7-39.3 22.7-46.8 45.5-2 6.4-2.5 9.7-2.5 19.2 0 19 4.3 31.3 15.9 45.4 12.9 15.6 29.3 23.8 
               50.7 25.3l8.9.6-37.8 37.8c-20.8 20.8-37.9 37.7-38 37.6-.3-.3-3.9-24.8-6.9-46.9-2.7-19.8-4.6-26.7-9.6
               -35.7-5.9-10.5-12.8-17.9-23.1-24.7-5.8-3.9-18.1-9.4-20.7-9.4-.5 0 .4 4.7 1.9 10.4 7.6 28.2 6.8 50.3-3 
               82.4-2.6 8.5-1.4 8.4-16.8 2.5-8.3-3.2-9.3-3.9-8.8-5.7 5.3-18.6 5.8-21.5 6.3-36.6.6-17.2-.7-26.9-5.4
               -41.7l-2.7-8.2-4.5-.6c-17.8-2.2-34.6-9.2-46.6-19.6-3.8-3.3-11.1-9.5-16.1-13.7-21.1-17.8-48.2-25.6-71.6
               -20.7-4 .9-18.5 5.3-32.3 9.9-57.3 19.2-67.6 23.5-74.2 31-3.9 4.5-5.7 7.1-43.6 64.1-12.4 18.7-26.2 39.2
               -30.7 45.4-11 15.5-12.3 18.3-12.3 25.6 0 24.3 28.8 35.6 45.4 17.9 3.6-3.8 18.4-24.2 44.9-61.9 6.9-9.9 
               15.6-22 19.2-26.9l6.5-8.9 39.4-11.4c21.7-6.2 40.6-11.6 42-11.9l2.7-.6-2.1 5.8c-2.5 7.4-3.3 20.5-1.7 28.7 
               1.7 9.1 7.3 20 20.5 40.2 30.6 46.9 57.8 89.7 72 113.5l9.9 16.4 3-6.4c4.3-9.3 38.7-81.2 42.7-89.3 1.8-3.7 
               3.6-6.7 3.9-6.7.3 0 4.1 10.7 8.5 23.7s9.4 26.6 11.2 30.3c7.7 15.9 25 25.8 40.5 23 8.6-1.6 14.4-4.8 23.5
               -13.1 4.3-4 31.9-31.1 61.3-60.3 59.5-59 58.4-57.7 58.4-70.1 0-7.9-1.3-11.2-7.2-17.7-4.5-5-8.7-7.6-17.7
               -11.2-10.2-4-17.4-8.7-26.3-17.1-20.5-19.3-31.5-45.4-29.9-70.8.7-10.8 2.5-18.3 7.1-29 3.9-8.9 4.1-8.8-7.4
               -5.4z"/>
      <path d="M645.6 56.2c-1.4 2.4-3.7 7.9-5.2 12.3-3.8 11.1-4 27.4-.6 38.5 6.8 21.5 19.9 37.4 38.7 46.6 5 2.4 9.4 4.4 
               9.8 4.4 1.2 0 5.7-8.8 8.2-16 7.8-23 4.5-44-10.2-64.7-4.4-6.2-14.1-14.8-21.8-19.3-3.7-2.1-13.8-6-15.6-6
               -.5 0-1.9 1.9-3.3 4.2zM331.7 287.3c-6.6 22.6-18 59-29.7 95.2-8.3 25.5-10 32.6-10 41.8 0 13.8 4.4 25.7 
               13.6 37.3 13.4 16.8 22.7 20.8 66.9 28.9 19.9 3.6 30.8 5.7 81.5 15.5 38.2 7.4 76.4 15.1 76.9 15.5 1.1 1.1
               -14.6 37.4-23.1 53.5-14.6 27.7-38.3 61.3-72.7 103.5-9.6 11.8-11.1 15.6-8.6 22.2 3.2 8.3 11.2 13.3 18.8 
               11.8 10.9-2.3 68.3-49.7 105.1-86.9 45.6-46.1 79-91.1 82.8-111.4 3.6-19.6-8.3-40.2-33.8-58.4-43.8-31.3
               -96.3-53.5-162.4-68.8-12.4-2.8-22.9-5.7-23.3-6.3-.4-.7-5-8.2-10-16.7-5.1-8.5-13.9-22.7-19.6-31.5-9.5-14.8
               -45.2-68.5-45.5-68.5 0 0-3.2 10.5-6.9 23.3z"/>
      <path d="M270.7 474.2c-.8 1.2-5.4 9-10.3 17.3-12.8 22.1-44.7 69.6-48.6 72.5-49.8 37-99.5 69.6-175.1 114.7-34.3 
               20.5-35.1 21.2-34.4 29.9.6 9.2 7.6 15.2 16.2 14.1 5-.7 59.9-17.2 83.6-25.1 104.3-34.8 170.4-70.7 228.4
               -124.3 14-12.9 32.2-31.9 40.5-42.3l5.2-6.5-8.5-1.1c-31.6-4.3-53.5-11.6-68.5-22.9-7.1-5.4-17-15.3-21.9
               -22-2.5-3.3-4.6-6.2-4.8-6.3-.2-.2-1 .7-1.8 2z"/>
    </svg>
  `,
  // endregion
};

const getIcon = (iconName: string | undefined) => (iconName ? icons?.[iconName] ?? iconName : '');

export { icons, getIcon };

export default getIcon;
