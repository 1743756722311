import { apiClient, createProjectBase, useLoginDialog } from '@velis/django_project_base';
import { AxiosResponse } from 'axios';
import { createApp } from 'vue';
import { createVuetify, ThemeDefinition } from 'vuetify';

import ALCApp from './main-app.vue';
import router from './router';

import '@velis/django_project_base/styles.css';
import '@velis/dynamicforms/styles.css';

const defaultTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f8f8f8',
    surface: '#ffffff',
    // 'primary-darken-1': '#3700B3',
    // 'secondary-darken-1': '#018786',
    primary: '#3f51b5',
    secondary: '#2196f3',
    accent: '#ffc107',
    error: '#f44336',
    warning: '#ff9800',
    info: '#8bc34a',
    success: '#00bcd4',
  },
};

createApp(ALCApp)
  .use(createVuetify({ defaults: {}, theme: { defaultTheme: 'defaultTheme', themes: { defaultTheme } } }))
  .use(router)
  .use(createProjectBase())
  .mount('#app');

apiClient.interceptors.response.use(
  (response: AxiosResponse<any, any>) => Promise.resolve(response),
  (error: any) => {
    if (error.response?.status === 403 && router.currentRoute.value.meta.projectRoute) {
      const { openLoginDialog } = useLoginDialog();
      openLoginDialog();
    }
    return Promise.reject(error);
  },
);
