<script setup lang="ts">

import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, FormConsumerApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

interface Props {
  activity: string
}

const props = defineProps<Props>();

const sessionStore = useUserSessionStore();
const { selectedProjectId } = storeToRefs(sessionStore);

const activityId = computed(() => props.activity);
const url = computed<string>(() => `rest-api/v1/activity/${activityId.value}/recurrence`);

const showConsumers = ref<boolean>(false);
const consumer = ref(new FormConsumerApi({
  url,
  trailingSlash: true,
  pk: undefined,
}));

const refresh = async () => {
  showConsumers.value = false;
  await consumer.value.getUXDefinition();
  showConsumers.value = true;
};

refresh();
watch([selectedProjectId, activityId], refresh);
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <APIConsumer
          v-if="showConsumers"
          :consumer="consumer"
          :display-component="ComponentDisplay.FORM"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
