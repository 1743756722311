<script setup lang="ts">
import { apiClient, useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, ConsumerLogicApi, FormConsumerOneShotApi } from '@velis/dynamicforms';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

import usePhoneCall from '../phone-call';

const props = defineProps<{
  activity: string,
}>();

const userSession = useUserSessionStore();

const activityObject = ref();
const loadActivity = async () => {
  activityObject.value = (await apiClient.get(`/rest-api/v1/activity/${props.activity}.json`)).data;
};
onMounted(async () => {
  await loadActivity();
});

const activityType = computed(() => activityObject.value?.att_state);
const isFullTimeTracking = computed<boolean>(() => activityType.value === 1);

const { selectedProjectId } = storeToRefs(userSession);

const selectedActivity = computed(() => props.activity);
const baseUrl = computed(() => `/rest-api/v1/activity-users-hours/${selectedActivity.value}`);
const hoursLogic = ref(new ConsumerLogicApi(baseUrl, true));

async function loadHours() {
  await hoursLogic.value.getFullDefinition();
}

const { actionCallUser } = usePhoneCall();

const handlers = {
  edit: async (action: any, payload: any): Promise<boolean> => {
    if (isFullTimeTracking.value) {
      // We are editing full time activity
      const url = `${baseUrl.value}/${payload.id}/`;
      const payloadCopy = _.cloneDeep(payload);
      payloadCopy.hours_full = !payloadCopy.hours_full;
      await apiClient.put(url, payloadCopy).then(async () => {
        payload.hours_full = payloadCopy.hours_full;
      }).catch(async () => {
        // TODO: error handling on TABLE
        console.error('Data could not be fetched');
      });
    } else {
      // We are editing duration or time to time activity
      await hoursLogic.value.dialogForm(payload.user);
    }
    return true;
  },
  'new-member': async (): Promise<boolean> => {
    const data = await FormConsumerOneShotApi(
      {
        url: `/rest-api/v1/activity/${selectedActivity.value}/members`,
        trailingSlash: true,
        pk: 'new',
      },
    );
    if (isFullTimeTracking.value) {
      const url = `${baseUrl.value}/${data.id}/`;
      data.hours_full = true;
      await apiClient.put(url, data);
    }
    await loadHours();
    return true;
  },
  'call-user': actionCallUser,
};

const attendanceString = computed<string>(() => {
  const rowData: any[] | undefined = hoursLogic.value?.rows?.data;
  if (rowData) {
    const fullCount = rowData.length;
    const attendanceCount = rowData.filter((data) => data.hours_full).length;
    return `${attendanceCount} / ${fullCount}`;
  }
  return '';
});

if (selectedProjectId.value) loadHours();
watch(selectedProjectId, loadHours);
watch(selectedActivity, async () => {
  await loadHours();
  await loadActivity();
});
</script>

<template>
  <div class="overflow-y-auto">
    <APIConsumer
      :consumer="hoursLogic"
      :display-component="ComponentDisplay.TABLE"
      :handlers="handlers"
    />
    <div
      v-if="isFullTimeTracking && attendanceString"
      class="bg-primary text-h5 elevation-8 rounded-s-pill count-tracker"
    >
      {{ attendanceString }}
    </div>
  </div>
</template>

<style>
.count-tracker {
  position: fixed;
  right: 3px;
  top: 45%;
  padding: 0.5rem 1rem;
}
</style>
