<script setup lang="ts">
import IonIcon from 'vue-ionicon';
import { useRoute } from 'vue-router';

import { doPlaceholderReplacements } from '../router';
import { getIcon } from '../utils';

import { useNavigationHandler, useNavigations } from './navigations';

const { navs, routeNavigation } = useNavigations();

const currentRouterPath = useRoute();

const { showNavigation } = useNavigationHandler();

</script>

<template>
  <div v-if="showNavigation">
    <v-navigation-drawer v-for="(navigations, key) in navs" :key="key" :rail="key == 0" expand-on-hover app>
      <v-list density="compact" nav class="fill-height me-1">
        <v-list-item
          v-for="route in navigations.top"
          :key="route?.title"
          :active="currentRouterPath?.name === route.name"
          @click="route.action()"
        >
          <template v-if="route.icon" #prepend>
            <ion-icon
              v-if="route.icon"
              :name="getIcon(route.icon)"
              style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
            />
          </template>
          <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <template #append>
        <v-list density="compact" nav class="fill-height me-1">
          <v-list-item
            v-for="route in navigations.bot"
            :key="route?.title"
            :active="currentRouterPath?.name === route.name"
            @click="routeNavigation(route)"
          >
            <template v-if="route.icon" #prepend>
              <ion-icon
                v-if="route.icon"
                :name="getIcon(route.icon)"
                style="width: 1.5em; height: 1.5em; margin-inline-end: 1em"
              />
            </template>
            <v-list-item-title>{{ doPlaceholderReplacements(route.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>
