<script setup lang="ts">
import { watch } from 'vue';
import { useDisplay } from 'vuetify';

import DesktopNavigation from './desktop-navigation.vue';
import MobileNavigation from './mobile-navigation.vue';
import { useNavigationHandler } from './navigations';

const { mobile } = useDisplay();
const { showNavigation } = useNavigationHandler();

watch(mobile, () => {
  showNavigation.value = !mobile.value;
}, { immediate: true });
</script>

<template>
  <mobile-navigation v-if="mobile"/>
  <desktop-navigation v-else/>
</template>

<style scoped>

</style>
