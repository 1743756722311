import { gettext } from '@velis/dynamicforms';

import ActivitiesOverview from '../activity/activities-overview.vue';
import ActivityDashboard from '../activity/activity-dashboard.vue';
import ActivityIndex from '../activity/activity-index.vue';
import ActivityMembers from '../activity/activity-members.vue';
import ActivityRecurrence from '../activity/activity-recurrence.vue';
import ActivityUsersWorkHours from '../activity/activity-users-work-hours.vue';
import ActivityWorkHours from '../activity/activity-work-hours.vue';
import GenericTableView from '../generic-table-view.vue';
import LandingPage from '../landing-page.vue';
import NotificationsEditor from '../notifications-editor.vue';
import PlaneReservations from '../plane-reservations.vue';
import ProfilesEditor from '../profiles-editor.vue';
import DefaultGroupRoles from '../settings/default-group-roles.vue';
import InvitesEditor from '../settings/invites-editor.vue';
import LocationEditor from '../settings/location-editor.vue';
import NotificationSettings from '../settings/notification-settings.vue';
import ProjectEditor from '../settings/project-editor.vue';
import { Modules } from '../settings/project-modules';
import ProjectModulesEditor from '../settings/project-modules-editor.vue';
import ProjectSettings from '../settings/project-settings.vue';
import RolesEditor from '../settings/roles-editor.vue';
import SelectMembers from '../settings/select-members.vue';
import SettingsIndex from '../settings/settings-index.vue';
import TagsEditor from '../settings/tags-editor.vue';
import StaticHTMLLoader from '../static-html-loader.vue';
import UserGroupsEditor from '../user-groups-editor.vue';

export default [
  {
    name: 'home',
    path: '/:project?',
    component: LandingPage,
    meta: { title: ':project_name', icon: 'home', projectRequired: false },
  },
  {
    name: 'chrono',
    path: '/:project/chrono/history',
    component: StaticHTMLLoader,
    meta: { title: gettext('Chronometry'), icon: 'chrono', requiredModules: [Modules.AVIATION] },
  },
  {
    name: 'users',
    path: '/:project/users',
    component: ProfilesEditor,
    meta: { title: gettext('Manage users'), icon: 'users' },
  },
  {
    name: 'user-groups',
    path: '/:project/user-groups',
    component: UserGroupsEditor,
    meta: { title: gettext('User groups'), icon: 'user_groups', requiredPermissions: ['is_club_owner'] },
  },
  {
    name: 'notifications',
    path: '/:project/notifications',
    component: NotificationsEditor,
    meta: {
      title: gettext('Notifications'),
      icon: 'notifications',
      requiredModules: [Modules.NOTIFICATIONS],
      requiredPermissions: ['is_club_owner'],
    },
  },
  {
    name: 'planes',
    path: '/:project/planes',
    component: GenericTableView,
    meta: {
      title: gettext('Manage planes'),
      icon: 'plane',
      requiredModules: [Modules.AVIATION],
      path: 'rest-api/v1/plane',
    },
  },
  {
    name: 'activity',
    path: '/:project/activity',
    meta: { title: gettext('Activities'), icon: 'activity', requiredModules: [Modules.ACTIVITIES] },
    children: [
      {
        name: 'activity-overview',
        path: '',
        component: ActivitiesOverview,
        meta: { title: gettext('Activities'), icon: 'plane' },
      },
      {
        name: 'activity-detail',
        path: ':activity',
        component: ActivityIndex,
        props: true,
        meta: { title: gettext('Activity Detail') },
        children: [
          {
            name: 'activity-dashboard',
            path: '',
            component: ActivityDashboard,
            props: true,
            meta: { title: gettext('Dashboard') },
          },
          {
            name: 'activity-users-hours',
            path: 'activity-users-hours',
            component: ActivityUsersWorkHours,
            props: true,
            meta: { title: gettext('Activity Work Hours') },
          },
          {
            name: 'my-activity-hours',
            path: 'my-activity-hours',
            component: ActivityWorkHours,
            props: true,
            meta: { title: gettext('My Work Hours') },
          },
          {
            name: 'activity-members',
            path: 'members',
            component: ActivityMembers,
            props: true,
            meta: { title: gettext('Members') },
          },
          {
            name: 'activity-recurrence',
            path: 'recurrence',
            component: ActivityRecurrence,
            props: true,
            meta: { title: gettext('Recurrence') },
          },
        ],
      },
    ],
  },
  {
    name: 'reservations',
    path: '/:project/plane_reservations/',
    component: PlaneReservations,
    meta: {
      title: gettext('Reservations'),
      icon: 'calendar',
      requiredModules: [Modules.AVIATION, Modules.RESERVATIONS],
    },
  },
  {
    path: '/:project/settings/',
    name: 'settings-index',
    component: SettingsIndex,
    meta: {
      title: gettext('Settings'),
      icon: 'settings-outline',
      navAppend: true,
      requiredPermissions: ['is_club_owner'],
    },
    children: [
      {
        name: 'settings',
        path: '',
        component: ProjectEditor,
        meta: { title: gettext('Project'), icon: 'settings-outline' },
      },
      {
        name: 'modules',
        path: 'plans',
        component: ProjectModulesEditor,
        meta: { title: gettext('Plans & modules'), icon: 'bag-outline', projectRequired: false },
      },
      {
        name: 'roles',
        path: 'roles',
        component: RolesEditor,
        meta: { title: gettext('Roles'), icon: 'role' },
      },
      {
        name: 'tags',
        path: 'tags',
        component: TagsEditor,
        meta: { title: gettext('Tags'), icon: 'tags' },
      },
      {
        name: 'default-group-roles',
        path: 'default-group-roles',
        component: DefaultGroupRoles,
        meta: { title: gettext('Default Group Roles'), icon: 'role' },
      },
      {
        name: 'select-users',
        path: 'select-users',
        component: SelectMembers,
        meta: { title: gettext('Selecting members'), icon: 'users', requiredModules: [Modules.AVIATION] },
      },
      {
        name: 'start-types',
        path: '/:project/start-types',
        component: GenericTableView,
        meta: {
          title: gettext('Start types'),
          icon: '/static/start-types.svg',
          requiredModules: [Modules.AVIATION],
          path: 'rest-api/v1/start-type',
        },
      },
      {
        name: 'intent',
        path: '/rest-api/v1/intent.html',
        component: GenericTableView,
        meta: {
          title: gettext('Manage intents'),
          icon: 'intent',
          requiredModules: [Modules.AVIATION],
          path: 'rest-api/v1/intent',
        },
      },
      {
        name: 'notification-settings',
        path: 'notification-settings',
        component: NotificationSettings,
        meta: {
          title: gettext('Notification settings'),
          icon: 'notifications',
          requiredModules: [Modules.NOTIFICATIONS],
        },
      },
      {
        name: 'invites',
        path: 'invites',
        component: InvitesEditor,
        meta: { title: gettext('Invites'), icon: 'invites' },
      },
      {
        name: 'project-settings',
        path: 'project-settings',
        component: ProjectSettings,
        meta: { title: gettext('Settings'), icon: 'tags' },
      },
      {
        name: 'locations',
        path: 'locations',
        component: LocationEditor,
        meta: { title: gettext('Locations'), icon: 'location-outline' },
      },
    ],
  },
  {
    name: 'forum',
    path: '/forum/',
    component: StaticHTMLLoader,
    meta: { title: gettext('Forum'), icon: 'forum', requiredModules: [Modules.AVIATION] },
  },
];
