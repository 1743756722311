<script setup lang="ts">
</script>

<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col>
        <project-settings/>
      </v-col>
    </v-row>
  </v-container>
</template>
