<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, FormConsumerApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const props = defineProps<{ activity: string }>();

const editDataLoaded = ref(false);
const url = 'rest-api/v1/activity/hours/';
const userSession = useUserSessionStore();
const { selectedProjectId } = storeToRefs(userSession);
// eslint-disable-next-line vue/no-setup-props-destructure
const consumer = ref(new FormConsumerApi({
  url,
  trailingSlash: true,
  pk: props.activity,
}));
const refresh = async () => {
  editDataLoaded.value = false;
  await consumer.value.getUXDefinition();
  editDataLoaded.value = true;
};
refresh();
watch(selectedProjectId, refresh);
</script>

<template>
  <v-container class="pa-0" fluid>
    <v-row v-if="editDataLoaded">
      <v-col>
        <APIConsumer :consumer="consumer" :display-component="ComponentDisplay.FORM"/>
      </v-col>
    </v-row>
  </v-container>
</template>
