<script setup lang="ts">
import { useUserSessionStore } from '@velis/django_project_base';
import { APIConsumer, ComponentDisplay, ConsumerLogicApi } from '@velis/dynamicforms';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

const userSession = useUserSessionStore();

const { selectedProjectId } = storeToRefs(userSession);
const consumer = ref(new ConsumerLogicApi('/rest-api/v1/location', true));

const load = async () => {
  await consumer.value.getFullDefinition();
};

if (selectedProjectId.value) load();
watch(selectedProjectId, load);
</script>

<template>
  <APIConsumer :consumer="consumer" :display-component="ComponentDisplay.TABLE"/>
</template>

<style scoped>

</style>
