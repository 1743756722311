<script setup lang="ts">
import { apiClient, APIConsumer, ComponentDisplay, FormConsumerApi, gettext } from '@velis/dynamicforms';
import { ref } from 'vue';

import { Navigation, Navigations } from '../navigation/namespace';
import { useSubNavigation } from '../navigation/sub-navigations';

const selectedScenarioTitle = ref('');
const selectedScenarioKey = ref('');
const scenarioLoaded = ref(false);
const scenarioUrl = '/rest-api/v1/member-selection/';
const scenarioLogic = ref(new FormConsumerApi({ url: scenarioUrl, trailingSlash: true, pk: selectedScenarioKey }));
const loadedScenarioKey = ref('');

async function selectScenario(scenario: Navigation) {
  selectedScenarioTitle.value = scenario.title ?? '';
  selectedScenarioKey.value = scenario.name;
  await scenarioLogic.value.getUXDefinition();
  scenarioLoaded.value = true;
  loadedScenarioKey.value = scenario.name;
}

const scenarios: Navigations = [
  { title: '', name: 'REZ-KRONO-P', action: () => {} },
  { title: '', name: 'REZ-UVOD-P', action: () => {} },
  { title: '', name: 'REZ-VLEKA-P', action: () => {} },
  { title: '', name: 'REZ-FLIGHT-P', action: () => {} },
  { title: gettext('Plane reservation pilot'), name: 'DEFAULT-P', action: () => {} },
  { title: gettext('Plane reservation instructor'), name: 'DEFAULT-I', action: () => {} },
];

async function fetchScenarioTitle(scenario: Navigation) {
  const planeData = await apiClient.get(
    `/rest-api/v1/plane.json?reg=${encodeURIComponent(scenario.name.slice(0, -2))}`,
  );
  scenario.title = planeData.data[0].type;
}

scenarios.forEach((scenario: Navigation) => {
  if (scenario.title === '') fetchScenarioTitle(scenario);
  scenario.action = () => selectScenario(scenario);
});

useSubNavigation(scenarios);
</script>

<template>
  <v-layout class="fill-height">
    <v-card class="flex-grow-1">
      <v-card-title>{{ gettext('Selecting club members in club activities') }}</v-card-title>
      <v-card-text>
        {{ gettext('Choose how club members will be selected in various scenarios across the app') }}
        <APIConsumer
          v-if="selectedScenarioKey && scenarioLoaded"
          :key="loadedScenarioKey"
          :consumer="scenarioLogic"
          :display-component="ComponentDisplay.FORM"
        />
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<style scoped>

</style>
